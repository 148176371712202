import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastService } from '../../shared/services/toast.service';
import { MixpanelService } from '../../shared/services/mixpanel.service';
import { UserRole } from '../../shared/models/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  hide = true;
  email: string;
  password: string;

  isLoading: boolean;
  error: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    protected route: ActivatedRoute,
    private toast: ToastService,
    private mixpanelService: MixpanelService
  ) {}

  protected getRoute(): ActivatedRoute {
    return this.route;
  }

  protected getAuthService(): AuthService {
    return this.authService;
  }

  protected getRouter(): Router {
    return this.router;
  }

  protected getToastService(): ToastService {
    return this.toast;
  }

  ngOnInit(): void {}

  async login() {
    if (this.isLoading) return;

    try {
      this.error = null;
      this.isLoading = true;
      const user = await this.authService.login(this.email, this.password);
      if (user.role !== UserRole.superAdmin) {
        // this.mixpanelService.registerAndCreateUserProfile(user); // TODO is it necessary?
      }
      const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
      this.router.navigate([returnUrl]);
    } catch (e) {
      this.toast.showError(e, false, '', true);
    } finally {
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.toast.onDismiss();
  }
}
