import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TableQueryParams } from '../models/table-query-params';
import { PagedResponse } from '../models/paged-response';
import { TableUtils } from '../utils/table.utils';

export abstract class CrudService<T> {
  public dataChangedSubject: Subject<boolean> = new Subject<boolean>();

  public dataChanged$(): Observable<boolean> {
    return this.dataChangedSubject;
  }

  protected constructor(protected http: HttpClient, protected endpoint: string) {}

  getAll(params: TableQueryParams): Promise<PagedResponse<T>> {
    const queryParams = TableUtils.prepareForApiParams(params);
    return this.http.get<PagedResponse<T>>(this.endpoint, { params: queryParams }).toPromise();
  }

  getOne(id: string): Promise<T> {
    return this.http.get<T>(`${this.endpoint}/${id}`).toPromise();
  }
  getOneForImage(id: number): Promise<T> {
    return this.http.get<T>(`${this.endpoint}/${id}`).toPromise();
  }

  async deleteOne(id: string) {
    const result = await this.http.delete(`${this.endpoint}/${id}`).toPromise();
    this.dataChangedSubject.next(true);
    return result;
  }

  async updateOne(id: string, data: Partial<T>, notRefresh: boolean = false) {
    const result = await this.http.put<T>(`${this.endpoint}/${id}`, data).toPromise();
    if (!notRefresh) {
      this.dataChangedSubject.next(true);
    }
    return result;
  }

  async addOne(data: Partial<T>) {
    const result = await this.http.post<T>(this.endpoint, data).toPromise();
    this.dataChangedSubject.next(true);
    return result;
  }
}
