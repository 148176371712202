import { Component, OnInit } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../shared/services/toast.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MixpanelService } from '../../shared/services/mixpanel.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ShopifyService } from '../../shared/services/shopify.service';
import { SalesChannel } from 'src/app/shared/models/sales-channel';
@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login-shopify.component.html',
  styleUrls: ['./login-shopify.component.scss'],
})
export class LoginShopifyComponent extends LoginComponent implements OnInit {
  store: string;
  timestamp: string;

  private _subscriptions$ = new Subscription();

  constructor(
    authService: AuthService,
    router: Router,
    route: ActivatedRoute,
    toast: ToastService,
    mixpanelService: MixpanelService,
    private shopifyService: ShopifyService
  ) {
    super(authService, router, route, toast, mixpanelService);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(async (params) => {
      const { store } = params;
      this.store = store;
    });
  }

  ngOnDestroy(): void {
    this._subscriptions$.unsubscribe();
  }

  async login() {
    const authService: AuthService = super.getAuthService();
    const toast: ToastService = super.getToastService();
    if (this.isLoading) return;
    try {
      this.error = null;
      this.isLoading = true;
      if (!this.store) {
        toast.showWarning('Missing store parameter.', false, '', true);
      } else {
        const shop = this.store.replace('.myshopify.com', '');
        const user = await authService.login(this.email, this.password);
        this.installShopifyStore(shop, user.companyId);
      }
    } catch (e) {
      toast.showError(e, false, '', true);
    } finally {
      this.isLoading = false;
    }
  }

  contact(): void {
    document.location.href = 'https://www.cratebase.io/contact';
  }

  installShopifyStore(store: string, companyId: string) {
    const router: Router = super.getRouter();
    const toast: ToastService = super.getToastService();

    this._subscriptions$.add(
      this.shopifyService.createShopifySalesChannel(store, companyId).subscribe({
        next: (res: SalesChannel) => {
          if (res) {
            router.navigate(['/settings/sales-channels']);
            toast.showSuccess('toast.success.addedSalesChannel');
          } else {
            toast.showError('toast.error.shopify.storeNotFound');
          }
        },
        error: (err) => {
          toast.showError(err.error.message);
        },
      })
    );
  }
}
