import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppInjector } from '../../../app.module';
import { SelectOption } from '../../models/select-option';

@UntilDestroy()
@Component({
  selector: 'app-selected-filter',
  templateUrl: './selected-filter.component.html',
  styleUrls: ['./selected-filter.component.scss'],
})
export class SelectedFilterComponent implements OnInit, OnChanges {
  @Input() queryLabel?: string;
  @Input() mapping?: SelectOption<string>[];

  @Input() hideFilterBool?: boolean;
  @Input() hiddenFilter?: string;
  @Output() hideFilterEmmiter = new EventEmitter<any>();

  public selectedFilters: SelectOption<string>[] = [];
  private queryParams: ParamMap;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((params) => {
      this.queryParams = params;
      this.updateSelectedFilters();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateSelectedFilters();
  }

  async removeFilter(channelFilter: SelectOption<string>) {
    const remainingFilters = this.selectedFilters
      .filter((el) => el.value != channelFilter.value)
      .map((el) => el?.value)
      .join(',');

    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { [this.queryLabel]: remainingFilters.length > 0 ? remainingFilters : null },
      queryParamsHandling: 'merge',
    });

    if (this.hideFilterBool) {
      await this.hideFilter(this.hiddenFilter);
    }
  }

  updateSelectedFilters() {
    if (this.queryParams?.has(this.queryLabel)) {
      this.selectedFilters = this.queryParams
        .get(this.queryLabel)
        .split(',')
        .map((el) => {
          return this.mapping?.find((sc) => sc.value === el);
        });
    } else {
      this.selectedFilters = [];
    }
  }

  async hideFilter(hiddenFilter: string): Promise<void> {
    this.hideFilterEmmiter.emit();

    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { [hiddenFilter]: null },
      queryParamsHandling: 'merge',
    });
  }
}
