export class StringUtils {
  public static textHumanize(value: string) {
    return (value || '').replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
  }

  public static textHumanizeCapitalizeWords(value: string) {
    return (value || '')
      .split(' ')
      .map((element) => this.textHumanize(element))
      .join(' ');
  }

  public static getInitials(value: string) {
    const name = value.toUpperCase().replace(/\s+/g, ' ').split(' ');
    return `${name[0][0]}${name.length > 1 ? name[1][0] : ''} `;
  }
  public static uncapitalizeFirstLetter(value: string) {
    return value.charAt(0).toLowerCase() + value.slice(1);
  }

  public static formatPhone(tel: string): string {
    const value = tel.toString().trim().replace(/^\+/, '');

    if (value.match(/[^0-9]/)) {
      return tel;
    }

    let country, city, number;

    switch (value.length) {
      case 10: // +CPPP###### -> + CP PP ## ## ##
        country = value.slice(0, 2);
        city = value.slice(2, 4);
        number = value.slice(4, 6) + ' ' + value.slice(6, 8) + ' ' + value.slice(8);
        break;

      case 11: // +CPPP####### -> + CP PP# ### ###
        country = value.slice(0, 2);
        city = value.slice(2, 5);
        number = value.slice(5, 8) + ' ' + value.slice(8);
        break;

      case 12: // +CCPPP####### -> + CC PPP ### ####
        country = value.slice(0, 2);
        city = value.slice(2, 5);
        number = value.slice(5, 8) + ' ' + value.slice(8);
        break;

      case 13: // +CCPPP######## -> + CC PPP #### ####
        country = value.slice(0, 2);
        city = value.slice(2, 5);
        number = value.slice(5, 9) + ' ' + value.slice(9);
        break;

      default:
        return tel;
    }
    return ('+ ' + country + ' ' + city + ' ' + number).trim();
  }
}
