import { Injectable } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
} from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { StringUtils } from '../utils/string.utils';
import { CustomSnackbarComponent } from '../components/custom-snackbar/custom-snackbar.component';

const SNACKBAR_OPTIONS: MatSnackBarConfig = {
  verticalPosition: 'top',
  duration: 4000,
};

const INDEFINITE_TIME_SNACKBAR: MatSnackBarConfig = {
  verticalPosition: 'top',
  duration: undefined,
};

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private snackbar: MatSnackBar, private translate: TranslateService) {}

  showError(error: any, translate: boolean = true, translateParams: any = {}, indefinite: boolean = false) {
    let message = '';
    if (error == null) return;
    if (typeof error === 'string') {
      message = error;
    } else if (typeof error === 'object') {
      message = (error.error || {}).message || error.message;
    }
    if (translate) {
      if (error.error?.code) {
        message = this.translate.instant(
          `errors.${StringUtils.uncapitalizeFirstLetter(error.error.code)}`,
          translateParams
        );
      } else {
        message = this.translate.instant(message, translateParams);
      }
    }

    const options = indefinite ? INDEFINITE_TIME_SNACKBAR : SNACKBAR_OPTIONS;
    this.snackbar.open(message, '✕', { ...options, panelClass: ['snackbar', 'error-snackbar'] });
  }

  showWarning(message: string, translate: boolean = true, translateParams: any = {}, indefinite: boolean = false) {
    if (translate) {
      message = this.translate.instant(message, translateParams);
    }
    const options = indefinite ? INDEFINITE_TIME_SNACKBAR : SNACKBAR_OPTIONS;
    this.snackbar.open(message, '✕', { ...options, panelClass: ['snackbar', 'warning-snackbar'] });
  }

  showSuccess(message: string, translate: boolean = true, translateParams: any = {}, indefinite: boolean = false) {
    if (translate) {
      message = this.translate.instant(message, translateParams);
    }
    const options = indefinite ? INDEFINITE_TIME_SNACKBAR : SNACKBAR_OPTIONS;
    this.snackbar.open(message, '✕', { ...options, panelClass: ['snackbar', 'success-snackbar'] });
  }

  showErrorWithRoute(message: string, linkedText: string, link: string, indefinite: boolean = false) {
    const options = indefinite ? INDEFINITE_TIME_SNACKBAR : SNACKBAR_OPTIONS;
    this.snackbar.openFromComponent(CustomSnackbarComponent, {
      ...options,
      data: { text: message, linkedText, link },
      panelClass: ['snackbar', 'error-snackbar'],
    });
  }

  onDismiss() {
    this.snackbar.dismiss();
  }
}
