import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { MixpanelService } from '../../shared/services/mixpanel.service';

@Component({
  selector: 'app-join-company',
  templateUrl: './join-company-component.html',
  styleUrls: ['./join-company-component.scss'],
})
export class JoinCompanyComponent implements OnInit, OnDestroy {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private mixpanelService: MixpanelService
  ) {}
  hide = true;
  password: string;
  isLoading: boolean;

  ngOnInit() {}

  async joinCompany() {
    if (this.isLoading) return;
    try {
      this.isLoading = true;
      const token = this.route.snapshot.paramMap.get('token');
      const user = await this.authService.joinCompany(token, this.password);
      const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/login';
      this.router.navigate([returnUrl]);
      this.toast.showSuccess('toast.success.joined');
      this.mixpanelService.registerAndCreateUserProfile(user);
    } catch (e) {
      this.toast.showError(e, false, '', true);
    } finally {
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.toast.onDismiss();
  }
}
