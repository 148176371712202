import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastService } from '../../shared/services/toast.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  email: string;

  isLoading: boolean;
  error: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService
  ) {}

  ngOnInit(): void {}

  async forgotPassword() {
    if (this.isLoading) return;

    try {
      this.error = null;
      this.isLoading = true;
      await this.authService.forgotPassword(this.email);
      const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/reset-password-sent';
      this.router.navigate([returnUrl]);
    } catch (e) {
      this.toast.showError(e, false, '', true);
    } finally {
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.toast.onDismiss();
  }
}
