import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../utils/string.utils';

@Pipe({
  name: 'formatPhone',
})
export class FormatPhonePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    return StringUtils.formatPhone(value);
  }
}
