import { MatSort, MatSortHeader } from '@angular/material/sort';
import { ParamMap } from '@angular/router';
import { TableQueryParams } from '../models/table-query-params';

export class TableUtils {
  // Sets the Angular Material Sort header in the table programmatically by manually
  // toggling the animation state. Workaround until it's fixed.
  static setSortColumn(matSort: MatSort, column: string) {
    if (matSort && column) {
      const header = matSort.sortables.get(column) as MatSortHeader;
      if (header) header._setAnimationTransitionState({ toState: 'active' });
    }
  }

  static prepareForApiParams(params: TableQueryParams) {
    let { column, sortOrder, start, length, search } = params as any;
    if (sortOrder) {
      sortOrder = sortOrder === 'desc' ? -1 : 1;
    }
    let queryParams: any = params;
    if (start && length) {
      queryParams = {
        ...queryParams,
        start: start.toString(),
        length: length.toString(),
      };
    }
    delete queryParams.sortOrder;
    if (column && sortOrder != null) {
      queryParams.column = column;
      queryParams.order = sortOrder;
    }
    if (search) {
      queryParams.search = encodeURIComponent(search);
    }
    return queryParams;
  }

  static parseParams(
    params: ParamMap,
    defaultPage = 0,
    defaultLength = 25,
    defaultColumn?: string,
    defaultSortOrder?: any
  ): TableQueryParams {
    const start = parseInt(params.get('start') || defaultPage.toString(), 10);
    const length = parseInt(params.get('length') || defaultLength.toString(), 10);
    const column = params.get('column') || defaultColumn;
    const sortOrder = (params.get('sortOrder') as any) || defaultSortOrder;

    // pass custom params to api
    const defaultParams = ['start', 'length', 'column', 'sortOrder'];
    const customParams = {};
    for (const key of params.keys.filter((item) => !defaultParams.includes(item))) {
      customParams[key] = params.get(key);
    }

    return Object.assign(
      {},
      start && { start },
      length && { length },
      column && { column },
      sortOrder && { sortOrder },
      customParams
    );
  }
}
