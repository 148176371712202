import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  @Input() withTitle: boolean = true;
  @Input() graysLevel: '050' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' = '900';

  constructor() {}

  ngOnInit(): void {}
}
