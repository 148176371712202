import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastService } from '../../shared/services/toast.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SalesChannelService } from '../../shared/services/sales-channel.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ImportOrderManualDialogComponent } from './components/import-order-manual-dialog/import-order-manual-dialog.component';
import { take } from 'rxjs/operators';
import { ImportOrderManualData, OrderService } from 'src/app/shared/services/order.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@UntilDestroy()
@Component({
  selector: 'import-orders-from-sales-channel',
  templateUrl: './import-orders-from-sales-channel.component.html',
  styleUrls: ['./import-orders-from-sales-channel.component.scss'],
})
export class ImportOrdersFromSalesChannelComponent implements OnInit, OnDestroy {
  @Output() refreshData = new EventEmitter();

  checkSyncInterval: number;
  dataTimestamp = new Date();
  isLoading = false;
  isMobile: boolean;

  constructor(
    private toast: ToastService,
    private salesChannelService: SalesChannelService,
    private orderService: OrderService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 1023px)']).subscribe((state: BreakpointState) => {
      this.isMobile = state.matches;
    });
  }

  async ngOnInit() {
    // @ts-ignore
    this.checkSyncInterval = setInterval(this.checkSyncStatus, 10000);
  }

  checkSyncStatus = async () => {
    const status = await this.salesChannelService.syncStatus();
    const newData = this.dataTimestamp < new Date(status.lastSyncDate);
    if (newData) {
      this.dataTimestamp = new Date();
      this.toast.showSuccess('toast.success.salesChannelsSynchronized', true, { count: status.lastSyncOrdersCount });
      this.refreshData.emit();
    } else if (status.syncing && !status.lastSyncDate) {
      this.toast.showWarning('toast.warning.salesChannelsSynchronizing');
    }
  };

  ngOnDestroy() {
    clearInterval(this.checkSyncInterval);
    this.toast.onDismiss();
  }

  async startSyncSalesChannel() {
    try {
      this.isLoading = true;
      const res = await this.salesChannelService.startSync();
      if (res.failed.length === 0) {
        this.toast.showSuccess('toast.success.importNewOrders', true, { success: res.success, failed: res.failed });
      } else {
        this.toast.showWarning('toast.warning.importNewOrders', true, { success: res.success, failed: res.failed });
      }
    } catch (e) {
      this.toast.showError(e, false, '', true);
    } finally {
      this.isLoading = false;
      this.refreshData.emit();
    }
  }

  openImportManualOrderModal() {
    this.dialog
      .open(ImportOrderManualDialogComponent)
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (importData?: ImportOrderManualData) => {
        if (importData) {
          this.importManualOrder(importData);
        }
      });
  }

  async importManualOrder(importData: ImportOrderManualData) {
    try {
      this.isLoading = true;
      await this.orderService.importManualOrder(importData);
      this.refreshData.emit();
      this.toast.showSuccess('toast.success.importManualOrder');
    } catch (e) {
      this.toast.showError(e, false, '', true);
    } finally {
      this.isLoading = false;
    }
  }
}
