import { BaseModel } from './base-model';
import CurrencyCode from './currency-code';
import { ShippingCarrierType, TaxableEntityType } from './shipping-carrier';

export enum WeightSystem {
  Metric = 'Metric',
  Imperial = 'Imperial',
}
export interface UpdateCompanyData {
  name?: string;
  phone?: string;
  vat?: string;
  address?: GeoAddress;
  timezone?: string;
  currencyCode?: CurrencyCode;
  weightSystem?: WeightSystem;
  defaultMessages?: DefaultMessages;
  customsSettings?: CustomsSettings;
  generalShippingSettings?: GeneralShippingSettings;
  markupPercentage?: number;
}

export interface Company extends BaseModel {
  name: string;
  salesChannelIds: string[];
  phone?: string;
  vat?: string;
  address?: GeoAddress;
  timezone?: string;
  currencyCode?: CurrencyCode;
  weightSystem?: WeightSystem;
  defaultMessages: DefaultMessages;
  customsSettings: CustomsSettings;
  generalShippingSettings?: GeneralShippingSettings;
  markupPercentage?: number;
}

export interface GeoAddress {
  countryCode: string;
  city: string;
  postalCode: string;
  state: string;
  address: string;
  address2?: string;
}

export interface DefaultMessages {
  orderReceivedMessage?: string;
  markOrderAsShippedMessage?: string;
  markOrderAsReadyForPickupdMessage?: string;
  askForFeedback?: string;
}

export interface CustomsSettings {
  defaultEORINumber?: string;
  defaultIOSSNumber?: string;
  defaultABNNumber?: string;
  defaultOrgNumber?: string;
  defaultItemValue?: number;
  defaultTypeOfExport?: CustomsTypeOfExport;
  defaultItemDescription?: string;
  defaultHarmonizationTarrifCode?: string;
  defaultSkuNumber?: string;
  defaultSkuDescription?: string;
  defaultNonDeliverySetting?: CustomsNonDeliveryOption;
  defaultCustomsValueCurrency?: CurrencyCode;
  defaultTaxableEntityType?: TaxableEntityType;
  defaultPhonenumber?: string;
  defaultCustomCountry?: string[];
}

export interface GeneralShippingSettingsOption {
  carrierName: string;
  carrierId: string;
  serviceCode: string;
  serviceName: string;
  type: ShippingCarrierType;
}

export interface GeneralShippingSettings {
  domestic?: GeneralShippingSettingsOption;
  customsArea?: GeneralShippingSettingsOption;
  international?: GeneralShippingSettingsOption;
}

export enum CustomsTypeOfExport {
  commercialGoods = 'merchandise',
  gift = 'gift',
  returnedGoods = 'returned_goods',
  documents = 'documents',
  sample = 'sample',
}

export enum CustomsNonDeliveryOption {
  returnToSender = 'return_to_sender',
  treatAsAbandoned = 'treat_as_abandoned',
}
