import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeEnGb from '@angular/common/locales/en-GB';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { RouterModule } from '@angular/router';
import { ErrorComponent } from './components/error/error.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BadgeComponent } from './components/badge/badge.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { FiltersComponent } from './components/filters/filters.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { QueryFiltersComponent } from './components/query-filters/query-filters.component';
import { TooltipRendererDirective } from './directives/custom-tooltip.directive';
import { ImagePreloaderDirective } from './directives/image-preloader.directive';
import { TextHumanizePipe } from './pipes/text-humanize.pipe';
import { TextHumanizeCapitalizeWordsPipe } from './pipes/text-humanize.pipe';
import { InputErrorComponent } from './components/input-error/input-error.component';
import { BackComponent } from './components/back/back.component';
import { UploadComponent } from './components/upload/upload.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { FileSizePipe } from './pipes/file-size.pipe';
import { DateDayAgoPipe } from './pipes/date-day-ago.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectFilterComponent } from './components/select-filter/select-filter.component';
import { SelectHttpFilterComponent } from './components/select-http-filter/select-http-filter.component';
import { SelectHttpInputComponent } from './components/select-http-input/select-http-input.component';
import { DateRangeFilterComponent } from './components/date-range-filter/date-range-filter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDateFormats, MatNativeDateModule, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { EmojiInputComponent } from './components/emoji-input/emoji-input.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { DragCursorDirective } from './directives/drag-cursor.directive';
import { OrderStatusBadgeComponent } from './components/order-status-badge/order-status-badge.component';
import { ButtonsFilterComponent } from '../order/orders-table/buttons-filter/buttons-filter.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ItemAssetConditionBadgeComponent } from './components/item-asset-condition-badge/item-asset-condition-badge.component';
import { MatIconModule } from '@angular/material/icon';
import { InitialsFromNamePipe } from './pipes/initials-from-name.pipe';
import { CrateIconComponent } from './components/crate-icon/crate-icon.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { FormatPhonePipe } from './pipes/format-phone.pipe';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';
import { DateTodayYesterdayPipe } from './pipes/date-today-yesterday.pipe';
import { SeparatorIconComponent } from './components/separator-icon/separator-icon.component';
import { ConfirmationDialogDirective } from './components/confirmation-dialog/confirmation-dialog.directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { httpLoaderFactory } from '../app.module';
import { MaxLengthDirective } from './directives/max-length.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { UncapitalizeFirstLetterPipe } from './pipes/uncapitalize-first-letter.pipe';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { SelectedFilterComponent } from './components/selected-filter/selected-filter.component';
import { DateTimezonePipe } from './pipes/date-timezone.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { LoadingDirective } from './directives/loading.directive';

registerLocaleData(localeEnGb);

export const DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour12: false,
    } as Intl.DateTimeFormatOptions,
  },
};

const materialModules = [
  MatCardModule,
  MatButtonModule,
  MatDialogModule,
  MatTooltipModule,
  MatMenuModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatChipsModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatButtonToggleModule,
  MatInputModule,
  MatIconModule,
  MatListModule,
  MatSelectModule,
];

const usedModules = [
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  HttpClientModule,
  NgSelectModule,
  PickerModule,
  OverlayModule,
  NgxExtendedPdfViewerModule,
];

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    ErrorComponent,
    AvatarComponent,
    ImagePreloaderDirective,
    TextHumanizePipe,
    TextHumanizeCapitalizeWordsPipe,
    BadgeComponent,
    CustomTooltipComponent,
    TooltipRendererDirective,
    PreventDoubleClickDirective,
    FiltersComponent,
    QueryFiltersComponent,
    SearchBarComponent,
    InputErrorComponent,
    BackComponent,
    UploadComponent,
    DragDropDirective,
    FileSizePipe,
    DateDayAgoPipe,
    DateTimezonePipe,
    SelectFilterComponent,
    SelectedFilterComponent,
    SelectHttpFilterComponent,
    SelectHttpInputComponent,
    DateRangeFilterComponent,
    DateFilterComponent,
    DateInputComponent,
    EmojiInputComponent,
    DragCursorDirective,
    MaxLengthDirective,
    OrderStatusBadgeComponent,
    ButtonsFilterComponent,
    ItemAssetConditionBadgeComponent,
    InitialsFromNamePipe,
    CrateIconComponent,
    SeparatorIconComponent,
    DialogComponent,
    FormatPhonePipe,
    CustomSnackbarComponent,
    DateTodayYesterdayPipe,
    ConfirmationDialogDirective,
    UncapitalizeFirstLetterPipe,
    SafePipe,
  ],
  imports: [
    CommonModule,
    LoadingDirective,
    ...materialModules,
    ...usedModules,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      extend: true,
    }),
  ],
  exports: [
    ...materialModules,
    ...usedModules,
    ErrorComponent,
    AvatarComponent,
    ImagePreloaderDirective,
    TextHumanizePipe,
    TextHumanizeCapitalizeWordsPipe,
    BadgeComponent,
    TooltipRendererDirective,
    PreventDoubleClickDirective,
    FiltersComponent,
    QueryFiltersComponent,
    SearchBarComponent,
    InputErrorComponent,
    BackComponent,
    UploadComponent,
    DragDropDirective,
    MaxLengthDirective,
    FileSizePipe,
    DateDayAgoPipe,
    DateTimezonePipe,
    SelectFilterComponent,
    SelectedFilterComponent,
    SelectHttpFilterComponent,
    SelectHttpInputComponent,
    DateRangeFilterComponent,
    DateFilterComponent,
    DateInputComponent,
    EmojiInputComponent,
    DragCursorDirective,
    OrderStatusBadgeComponent,
    ButtonsFilterComponent,
    ItemAssetConditionBadgeComponent,
    InitialsFromNamePipe,
    CrateIconComponent,
    SeparatorIconComponent,
    DialogComponent,
    FormatPhonePipe,
    CustomSnackbarComponent,
    DateTodayYesterdayPipe,
    ConfirmationDialogDirective,
    TranslateModule,
    UncapitalizeFirstLetterPipe,
    SafePipe,
    LoadingDirective,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'en-GB',
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class SharedModule {}
