<section>
  <app-logo [withTitle]="true" [graysLevel]="'600'"></app-logo>
  <mat-card>
    <mat-card-title>
      {{ stepTitles[currentStep] | translate }}
    </mat-card-title>
    <mat-card-content>
      <form #registrationForm="ngForm">
        <div *ngIf="currentStep === 0">
          <div class="form-group">
            <label for="fullName">{{ 'register.fullNameLabel' | translate }}</label>
            <input
              [(ngModel)]="user.fullName"
              id="fullName"
              name="fullName"
              [placeholder]="'register.fullNameInput' | translate"
              autocomplete="full-name"
              type="text"
              required
              #fullNameModel="ngModel"
            />
            <app-input-error>{{ 'register.fullNameError' | translate }}</app-input-error>
          </div>

          <div class="form-group">
            <label for="email">{{ 'register.emailLabel' | translate }}</label>
            <input
              [(ngModel)]="user.email"
              type="email"
              id="email"
              name="email"
              [placeholder]="'register.emailInput' | translate"
              autocomplete="email"
              required
              #emailModel="ngModel"
            />
            <app-input-error>{{ 'register.emailError' | translate }}</app-input-error>
          </div>

          <div class="form-group">
            <label for="password">{{ 'register.passwordLabel' | translate }}</label>
            <input
              [(ngModel)]="user.password"
              [type]="hide ? 'password' : 'text'"
              id="password"
              name="password"
              [placeholder]="'register.passwordInput' | translate"
              minlength="6"
              required
              #passwordModel="ngModel"
            />
            <app-input-error>{{ 'register.passwordError' | translate }}</app-input-error>
            <button mat-icon-button class="suffix transparent-bg customShowPass" (click)="hide = !hide">
              <mat-icon [inline]="true" [svgIcon]="hide ? 'show_off' : 'show_on'"></mat-icon>
            </button>
          </div>

          <div class="form-group">
            <mat-checkbox
              id="agreeTerms"
              name="agreeTerms"
              [checked]="agreeTerms"
              (change)="agreeTerms = !agreeTerms; error = ''"
              required
              type="checkbox"
            >
              <label for="agreeTerms" class="agreeTerms">
                {{ 'register.iAgreeTerm1' | translate }}
                <a routerLink="/terms-conditions">{{ 'register.iAgreeTerm2' | translate }}</a>
                {{ 'register.iAgreeTerm3' | translate }}
                <a routerLink="/privacy">{{ 'register.iAgreeTerm4' | translate }}</a>
              </label>
            </mat-checkbox>
          </div>

          <div class="form-group">
            <button
              mat-flat-button
              type="button"
              color="primary"
              [disabled]="registrationForm.invalid || !agreeTerms"
              [appLoading]="isLoading"
              (click)="nextStep()"
            >
              {{ 'register.stepButton1' | translate }}
            </button>
          </div>
        </div>

        <div *ngIf="currentStep === 1">
          <div class="form-group">
            <label for="companyName">{{ 'register.companyNameLabel' | translate }}</label>
            <input
              [(ngModel)]="company.name"
              id="companyName"
              name="companyName"
              [placeholder]="'register.companyNameInput' | translate"
              required
              #companyName="ngModel"
            />
            <app-input-error>{{ 'register.companyNameError' | translate }}</app-input-error>
          </div>
          <div class="form-group">
            <label for="companyPhone">{{ 'register.companyPhoneLabel' | translate }}</label>
            <input
              [(ngModel)]="company.phone"
              id="companyPhone"
              name="companyPhone"
              [placeholder]="'register.companyPhoneInput' | translate"
            />
          </div>
          <div class="form-group">
            <label for="companyVat">{{ 'register.companyVatLabel' | translate }}</label>
            <input
              [(ngModel)]="company.vat"
              id="companyVat"
              name="companyVat"
              [placeholder]="'register.companyVatInput' | translate"
            />
          </div>

          <div class="form-group">
            <button
              mat-flat-button
              color="primary"
              [appLoading]="isLoading"
              type="button"
              [disabled]="companyName.invalid"
              (click)="register()"
            >
              {{ 'register.stepButton2' | translate }}
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-footer>
      <div>
        {{ 'register.footerText1' | translate }}<a routerLink="/login">{{ 'register.footerText2' | translate }}</a>
      </div>
    </mat-card-footer>
  </mat-card>
</section>
