import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { Company } from '../models/company';

@Injectable({
  providedIn: 'root',
})
export class SuperAdminCompanyService extends CrudService<Company> {
  constructor(http: HttpClient) {
    super(http, 'admin/companies');
  }
}
