<section>
  <app-logo [withTitle]="true" [graysLevel]="'600'"></app-logo>
  <mat-card>
    <mat-card-title>{{ 'login.title' | translate }}</mat-card-title>
    <mat-card-content>
      <div class="form-group">
        <label for="email">{{ 'login.emailLabel' | translate }}</label>
        <input
          [(ngModel)]="email"
          type="email"
          id="email"
          name="email"
          (keyup.enter)="login()"
          [placeholder]="'login.emailInput' | translate"
          autocomplete="email"
        />
      </div>

      <div class="form-group">
        <label for="password">{{ 'login.passwordLabel' | translate }}</label>
        <input
          [(ngModel)]="password"
          [type]="hide ? 'password' : 'text'"
          id="password"
          name="password"
          autocomplete="current-password"
          (keyup.enter)="login()"
          [placeholder]="'login.passwordInput' | translate"
        />
        <app-input-error>{{ 'login.passwordError' | translate }}</app-input-error>
        <button mat-icon-button class="suffix transparent-bg customShowPass" (click)="hide = !hide">
          <mat-icon [inline]="true" [svgIcon]="hide ? 'show_off' : 'show_on'"></mat-icon>
        </button>
      </div>
      <div class="form-group">
        <a routerLink="/forgot-password">{{ 'login.forgotPassword' | translate }}</a>
      </div>
      <div class="form-group">
        <button mat-flat-button color="primary" (click)="login()" [appLoading]="isLoading">
          {{ 'login.buttonLogin' | translate }}
        </button>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <!-- <div>
        {{ 'login.footerText1' | translate }}<a routerLink="/register">{{ 'login.footerText2' | translate }}</a>
      </div> -->
    </mat-card-footer>
  </mat-card>
</section>
