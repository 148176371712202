import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrderStatus } from '../../models/order';

@Component({
  selector: 'app-order-status-badge',
  templateUrl: './order-status-badge.component.html',
  styleUrls: ['./order-status-badge.component.scss'],
})
export class OrderStatusBadgeComponent implements OnInit, OnChanges {
  @Input() status: OrderStatus;

  color: string;
  icon: string;
  constructor() {}

  setStatus() {
    switch (this.status) {
      case OrderStatus.newOrder: {
        this.color = 'status-blue-500';
        this.icon = '';
        break;
      }
      case OrderStatus.onHold: {
        this.color = 'status-orange-200';
        this.icon = '';
        break;
      }
      case OrderStatus.picked: {
        this.color = 'primary-yellow-100';
        this.icon = 'assets/icons/picked.svg';
        break;
      }
      case OrderStatus.packed: {
        this.color = 'status-purple-500';
        this.icon = 'assets/icons/packed.svg';
        break;
      }
      case OrderStatus.shipped: {
        this.color = 'status-green-300';
        this.icon = '/assets/icons/shipped.svg';
        break;
      }
      case OrderStatus.pickUp: {
        this.color = 'status-purple-500';
        this.icon = 'assets/icons/pickup.svg';
        break;
      }
      default: {
        this.color = '';
        this.icon = '';
      }
    }
  }

  ngOnInit(): void {
    this.setStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setStatus();
  }
}
