import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss'],
})
export class BackComponent implements OnInit {
  @Input() route: string;
  @Input() onlyArrow: boolean = false;

  constructor(private router: Router, private location: Location) {}

  ngOnInit(): void {}

  onBack() {
    if (this.router.navigated) {
      this.location.back();
    } else {
      this.router.navigateByUrl(this.route);
    }
  }
}
