<section class="toolbar">
  <!-- <div *ngIf="isMobile && !canGoBack" class="logo-container">
    <img src="/assets/images/logo.png" alt="Logo" class="logo" />
  </div> -->
  <app-back *ngIf="canGoBack" route="/orders" [onlyArrow]="withTitle"></app-back>
  <div *ngIf="isMobile" class="toggle-menu">
    <button mat-button class="menu-button" (click)="menuToggle.emit()">
      <img src="/assets/icons/menu.svg" />
    </button>
  </div>
  <div *ngIf="!isMobile && withTitle" class="title">
    <h2>{{ 'navbar.' + title | translate: { name: this.company?.name } }}</h2>
  </div>
  <div>
    <app-user-dropdown></app-user-dropdown>
  </div>
</section>
