<section>
  <app-logo [withTitle]="true" [graysLevel]="'600'"></app-logo>
  <mat-card>
    <mat-card-title>{{ 'joinCompany.title' | translate}}</mat-card-title>
    <mat-card-content>
      <form #joinCompanyForm="ngForm">
        <div class="details">{{ 'joinCompany.details1' | translate}}</div>
        <div class="form-group">
          <label for="password">{{ 'joinCompany.passwordLabel' | translate}}</label>
          <input
            [(ngModel)]="password"
            [type]="hide ? 'password' : 'text'"
            id="password"
            name="password"
            (keyup.enter)="joinCompany()"
            [placeholder]="'joinCompany.passwordInput' | translate"
            minlength="6"
            required
          />
          <app-input-error>{{ 'joinCompany.passwordError' | translate}}</app-input-error>
          <button mat-icon-button class="suffix transparent-bg customShowPass" (click)="hide = !hide">
            <mat-icon [inline]="true" [svgIcon]="hide ? 'show_off' : 'show_on'"></mat-icon>
          </button>
        </div>
        <div class="details">{{ 'joinCompany.details2' | translate}}</div>
        <div class="form-group">
          <button
            mat-flat-button
            color="primary"
            (click)="joinCompany()"
            [disabled]="joinCompanyForm.invalid"
            [appLoading]="isLoading"
          >
            {{ 'joinCompany.buttonJoin' | translate}}
          </button>
        </div>
      </form>
    </mat-card-content>
    <mat-card-footer> </mat-card-footer>
  </mat-card>
</section>
