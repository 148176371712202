import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { InviteUserData, User } from '../models/user';
import { HttpClient } from '@angular/common/http';
import { WeightSystem } from '../models/company';
import { PackageUnit } from '../models/order';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenSubject: Subject<string> = new ReplaySubject(1);

  get token$(): Observable<string> {
    return this.tokenSubject.asObservable();
  }

  private userSubject: BehaviorSubject<User> = new BehaviorSubject(null);

  get user$(): Observable<User> {
    return this.userSubject.asObservable();
  }

  get user(): User {
    return this.userSubject.value;
  }

  private token: string;

  constructor(private router: Router, private http: HttpClient) {
    this.getDataFromStorage();
  }

  getDefaultWeightSystem(): WeightSystem {
    const weightSystem = this.user.company?.weightSystem ?? WeightSystem.Metric;
    return weightSystem;
  }

  isWeightSystemMetric() {
    return this.getDefaultWeightSystem() === WeightSystem.Metric;
  }

  getDataFromStorage() {
    const token = sessionStorage.getItem('authToken') || localStorage.getItem('authToken');
    const user = JSON.parse(sessionStorage.getItem('user') || localStorage.getItem('user'));

    // If the user has saved auth data with the old user model
    if (user) {
      if (!user.role && user.type) user.role = user.type;
    }
    this.tokenSubject.next(token || null);
    this.token = token;
    this.userSubject.next(user);
  }

  async login(email: string, password: string, rememberMe = true) {
    if (!email || !password) {
      throw new Error('Invalid email or password');
    }

    const resp = await this.http.post<{ user: User; authToken: string }>('auth/login', { email, password }).toPromise();
    this.handleLoginResponse(resp, rememberMe);
    return resp.user;
  }

  async installShopify(hmac: string, host: string, shop: string, timestamp: string, email?: string, rememberMe = true) {
    const resp = await this.http
      .post<{ oAuthUrl: string; user: User }>('auth/install-shopify', {
        email,
        hmac,
        host,
        shop,
        timestamp,
      })
      .toPromise();
    return resp.oAuthUrl;
  }

  async impersonate(companyId: string) {
    const resp = await this.http
      .post<{ user: User; authToken: string }>('admin/auth/impersonate', { companyId })
      .toPromise();
    this.handleLoginResponse(resp);
    return resp.user;
  }

  async register(user: Partial<User>, company: { name: string }) {
    const resp = await this.http
      .post<{ user: User; authToken: string }>('auth/register', { user, company })
      .toPromise();
    this.handleLoginResponse(resp);
    return resp.user;
  }

  async forgotPassword(email: string) {
    return await this.http.post<{ emailSent: boolean }>('auth/forgot-password', { email }).toPromise();
  }

  async sendUserInvitation(data: InviteUserData): Promise<User> {
    return this.http.post<User>('auth/invite', data).toPromise();
  }

  async resetPassword(token: string, newPassword: string) {
    return await this.http
      .post<{ passwordChanged: boolean }>('auth/reset-password', { token, newPassword })
      .toPromise();
  }

  async joinCompany(token: string, password: string) {
    return await this.http.put<User>('auth/join-company', { token, password }).toPromise();
  }

  async validateEmail(email: string) {
    const resp = await this.http.post<{ valid: boolean }>(`auth/validate`, { email }).toPromise();
    this.handleValidateResponse(resp);
  }

  private handleValidateResponse(resp: { valid: boolean }) {
    if (!resp.valid) {
      throw new Error('Invalid email');
    }
  }

  public updateUser(user: Partial<User>) {
    Object.entries(user).forEach(([key, value]) => {
      this.user[key] = value;
    });
    if (localStorage.getItem('user')) {
      localStorage.setItem('user', JSON.stringify(this.user));
    }
    sessionStorage.setItem('user', JSON.stringify(this.user));
    this.userSubject.next(this.user);
  }

  private handleLoginResponse(resp: { user: User; authToken: string }, rememberMe = true) {
    const { user, authToken } = resp;

    if (rememberMe) {
      localStorage.setItem('authToken', authToken);
      localStorage.setItem('user', JSON.stringify(user));
    }
    sessionStorage.setItem('authToken', authToken);
    sessionStorage.setItem('user', JSON.stringify(user));
    this.tokenSubject.next(authToken);
    this.token = authToken;
    this.userSubject.next(user);
  }

  async logout(email?: string) {
    await this.http.post('auth/logout', { email }, { responseType: 'text' }).toPromise();
    this.clearLocalStorage();
  }

  async clearLocalStorage() {
    sessionStorage.clear();
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    this.tokenSubject.next(null);
    this.userSubject.next(null);
    this.token = null;
    await this.router.navigate(['/login']);
  }

  isLoggedIn() {
    return !!this.token;
  }

  public async getDiscogsCredentials() {
    const discogsConsumerKey = localStorage.getItem('discogs-consumer-key');
    const discogsConsumerSecret = localStorage.getItem('discogs-consumer-secret');
    if (discogsConsumerKey && discogsConsumerSecret) {
      return { discogsConsumerKey, discogsConsumerSecret };
    }
    const credentials = await this.http
      .get<{ discogsConsumerKey: string; discogsConsumerSecret: string }>(`auth/discogs/credentials`)
      .toPromise();
    localStorage.setItem('discogs-consumer-key', credentials.discogsConsumerKey);
    localStorage.setItem('discogs-consumer-secret', credentials.discogsConsumerSecret);
    return credentials;
  }

  public async discourseSSOLogin(email: string, ssoPayload: string, sigPayload: string): Promise<any> {
    return await this.http
      .get(`auth/discourse-login/sso?sso=${ssoPayload}&sig=${sigPayload}&email=${email}`)
      .toPromise();
  }

  public async discourseSSOLogout(user: User): Promise<void> {
    await this.http.get(`auth/discourse-logout/sso?email=${user.email}`).toPromise();
  }
}
