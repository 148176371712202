import { AfterViewInit, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Constants } from 'src/app/shared/utils/constants';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NgSelectConfig } from '@ng-select/ng-select';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import SwiperCore, { Pagination } from 'swiper';
import { TranslateService } from '@ngx-translate/core';

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  isLoggedIn: boolean = false;
  isMobile: boolean = false;
  condensed: boolean = false;

  url: string;

  sidenavOpened = false;

  routesWithoutNavbar = [
    '/login',
    '/login-shopify',
    '/shopify',
    '/register',
    '/forgot-password',
    '/reset-password',
    '/connect-discogs',
    '/join-company',
    '/shipping',
  ];

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private ngSelectConfig: NgSelectConfig,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public translate: TranslateService
  ) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    translate.use('en');

    this.ngSelectConfig.appendTo = 'body';
    this.ngSelectConfig.bindValue = 'value';
    this.ngSelectConfig.bindLabel = 'label';

    // icons for show/hide on password input fields
    this.matIconRegistry.addSvgIcon(
      'show_on',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eye.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'show_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eye-off.svg')
    );
  }

  ngOnInit() {
    document.title = environment.production ? Constants.appName : `${Constants.appName} - ${environment.name}`;
    this.setScrollbarWidth();
  }

  ngAfterViewInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      let url = (event as NavigationEnd).urlAfterRedirects;
      this.isLoggedIn = !this.routesWithoutNavbar.some((r) => url.startsWith(r));
      this.url = url;
      if (this.isLoggedIn) {
        setTimeout(() => this.handleMenu(), 0);
      }
    });

    this.breakpointObserver.observe(['(max-width: 1023px)']).subscribe((state: BreakpointState) => {
      setTimeout(() => {
        this.isMobile = state.matches;
        if (this.isLoggedIn) {
          this.handleMenu();
        }
        this.setScrollbarWidth();
      });
    });
  }

  setScrollbarWidth() {
    // https://codepen.io/shshaw/pen/JqGmKx
    const scroller: any = document.scrollingElement;
    // Force scrollbars to display
    scroller.style.setProperty('overflow', 'scroll');
    // Wait for next from so scrollbars appear
    requestAnimationFrame(() => {
      // True width of the viewport, minus scrollbars
      scroller.style.setProperty('--vw', `${scroller.clientWidth / 100}px`);
      // Width of the scrollbar
      scroller.style.setProperty('--scrollbar-width', `${window.innerWidth - scroller.clientWidth}px`);
      // Reset overflow
      scroller.style.setProperty('overflow', '');
    });
  }

  handleMenu() {
    this.sidenavOpened = !this.isMobile;
  }

  async toggleSidenav() {
    if (this.isMobile) {
      this.sidenavOpened = !this.sidenavOpened;
    } else {
      this.condensed = !this.condensed;
    }
  }
}
