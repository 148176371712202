import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AssetCondition } from '../../models/order';

@Component({
  selector: 'app-item-asset-condition',
  templateUrl: './item-asset-condition-badge.component.html',
  styleUrls: ['./item-asset-condition-badge.component.scss'],
})
export class ItemAssetConditionBadgeComponent implements OnChanges {
  @Input() condition: AssetCondition;
  @Input() icon: string;

  color: string;
  colors: { [key: string]: string } = {};
  constructor() {
    this.colors[AssetCondition.mint] = 'condition-mint';
    this.colors[AssetCondition.near_mint] = 'condition-near-mint';
    this.colors[AssetCondition.very_good_plus] = 'condition-very-good-plus';
    this.colors[AssetCondition.very_good] = 'condition-very-good';
    this.colors[AssetCondition.good_plus] = 'condition-good-plus';
    this.colors[AssetCondition.good] = 'condition-good';
    this.colors[AssetCondition.fair] = 'condition-fair';
    this.colors[AssetCondition.poor] = 'condition-poor';
    this.colors[AssetCondition.generic] = 'condition-generic';
    this.colors[AssetCondition.shopify] = 'condition-generic';
  }

  parseCondition(): string {
    try {
      return this.condition.split('(')[1].split(')')[0];
    } catch (e) {}
    return this.condition;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.color = this.colors[this.condition];
  }
}
