import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'dayAgo',
})
export class DateDayAgoPipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(date: string, ...args: any[]): any {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const dateLocale = dayjs(date).tz(this.authService.user.company.timezone);

    return dayjs().diff(dateLocale, 'days');
  }
}
