export interface Message {
  from?: {
    username: string;
    resource_url: string;
  };
  actor?: {
    username: string;
    resource_url: string;
  };
  refund?: {
    amount: number;
    order: {
      resource_url: string;
      id: string;
    };
  };
  original?: number;
  new?: number;
  message: string;
  order?: {
    resource_url: string;
    id: string;
  };
  timestamp: Date;
  subject?: string;
  type: MessageType;
}

export enum MessageType {
  status = 'status',
  refundReceived = 'refund_received',
  refundSent = 'refund_sent',
  message = 'message',
  shipping = 'shipping',
}
