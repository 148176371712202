<div
  [ngClass]="{ square: type === 'square', circle: type === 'circle' }"
  [ngStyle]="{ width: imgWidth, height: imgHeight, overflow: 'hidden' }"
  [attr.alt]="alt"
>
  <img *ngIf="src" [imgPreloader]="src" />
  <div class="initials" *ngIf="fullName">
    <span [ngStyle]="{ 'font-size': fontSize() }">
      {{ fullName | getInitials }}
    </span>
  </div>
</div>
