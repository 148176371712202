import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { ToastService } from '../../shared/services/toast.service';
import { User, UserRoleTranslations } from '../../shared/models/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
})
export class UserDropdownComponent implements OnDestroy {
  user: User;
  userRole: string;
  isLoggingOut = false;
  isDropdownOpen: boolean = false;
  private userSubscription: Subscription;

  constructor(private toast: ToastService, private authService: AuthService) {}

  ngOnInit(): void {
    this.userSubscription = this.authService.user$.subscribe((user) => {
      this.user = user;
      this.userRole = UserRoleTranslations[this.user.role];
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  async logout() {
    try {
      this.isLoggingOut = true;
      await this.authService.logout(this.authService.user.email);
    } catch (e) {
      this.toast.showError(e, false, '', true);
    } finally {
      this.isLoggingOut = false;
    }
  }
}
