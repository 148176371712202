import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectOption } from '../../models/select-option';

enum FieldTypes {
  salesChannelIds = 'channel',
  carriersIds = 'carrier',
  servicesIds = 'service',
  countriesIds = 'country',
}

@UntilDestroy()
@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
})
export class SelectFilterComponent implements OnInit {
  @Input() customIcon: boolean = false;
  @Input() field: string;
  @Input() placeholder: string;
  @Input() options: SelectOption<string>[];
  @Input() multiple = false;
  @Input() imageField: string;
  @Input() forQuery = true;
  @Input() maxNumberSelect: number;

  @Output() valueChangedEmmiter = new EventEmitter<string[]>();

  values: any[];
  outputField: string;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((params) => {
      this.values =
        params
          .get(this.field)
          ?.split(',')
          ?.map((item) => this.options.find((option) => option.value === item)) ?? [];
      if (!this.multiple) this.values = (this.values || [])[0];
    });
    this.outputField = FieldTypes[this.field];
  }

  async valueChanged(items) {
    if (!this.multiple) items = items ? [items] : [];
    items = items?.map((item) => item?.value);
    this.valueChangedEmmiter.emit(items);
    if (!this.forQuery) return;

    const queryParams: any = {};
    queryParams[this.field] = items.join(',') || null;
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });

    if (queryParams['carriersIds'] === null) {
      await this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { servicesIds: null },
        queryParamsHandling: 'merge',
      });
    }
  }
}
