import { Component, forwardRef, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject, Observable, concat, of, combineLatest, merge } from 'rxjs';
import { map, distinctUntilChanged, startWith, debounceTime, tap, switchMap, catchError } from 'rxjs/operators';
import { AutocompleteService } from '../../services/autocomplete.service';
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'app-select-http-input',
  templateUrl: './select-http-input.component.html',
  styleUrls: ['./select-http-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectHttpInputComponent),
      multi: true,
    },
  ],
})
export class SelectHttpInputComponent extends AbstractInputComponent implements OnInit {
  @ViewChild(NgSelectComponent, { static: false }) select: NgSelectComponent;

  input$ = new Subject<string>();

  @Input() url: string;
  @Input() labelField: string;
  @Input() valueField: string;
  @Input() imageField: string;
  @Input() additionalFields: string; // This fields will be passed to the "summary" query param
  @Input() queryParams: { [key: string]: string } = {};
  @Input() maxSuggestions: number;
  @Input() labelFieldFunction: (item: any) => string;

  isLoading = false;
  items$: Observable<any>;
  itemsSubject = new Subject();

  constructor(private autocompleteService: AutocompleteService) {
    super();
  }

  ngOnInit() {}

  async valueChanged() {
    if (!this.componentInit) return;

    let initialObject;
    if (this.value) {
      this.items$ = concat(
        of([]),
        merge(
          this.itemsSubject.asObservable(),
          this.input$.pipe(
            // startWith(''),
            distinctUntilChanged(),
            debounceTime(400),
            tap(() => (this.isLoading = true)),
            switchMap((value) => this._filter(value))
          )
        )
      );
      await this.getValueById();
    } else {
      this.items$ = concat(
        of([]),
        merge(
          this.itemsSubject.asObservable(),
          this.input$.pipe(
            startWith(''),
            distinctUntilChanged(),
            debounceTime(400),
            tap(() => (this.isLoading = true)),
            switchMap((value) => this._filter(value))
          )
        )
      );
    }
  }

  async getValueById() {
    const v = await this.autocompleteService.findByIds(this.url, this.value).toPromise();
    const value = (v || [])[0] || {};
    console.log(v);
    this.itemsSubject.next([
      {
        label: value[this.labelField] || this.labelFieldFunction(value),
        value: value[this.valueField],
        ...(this.imageField && { image: value[this.imageField] }),
      },
    ]);
    return value;
  }

  private _filter(value: string) {
    const filterValue = (value || '').toLowerCase();
    return this.autocompleteService
      .search({
        url: this.url,
        search: filterValue,
        valueField: this.valueField,
        labelField: this.labelField,
        queryParams: this.queryParams,
        maxSuggestions: this.maxSuggestions,
        imageField: this.imageField,
        additionalFields: this.additionalFields,
      })
      .pipe(
        map((val) =>
          val.map((item) => ({
            label: item[this.labelField] || this.labelFieldFunction(item),
            value: item[this.valueField],
            ...(this.imageField && { image: item[this.imageField] }),
          }))
        ),
        catchError(() => of([])),
        tap(() => (this.isLoading = false))
      );
  }
}
