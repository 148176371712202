import { BaseModel } from './base-model';

export enum SalesChannelType {
  discogs = 'discogs',
  nasdisc = 'nasdisc',
  shopify = 'shopify',
  ebay = 'ebay',
  bandcamp = 'bandcamp',
}

export enum SalesChannelStatus {
  pending = 'pending',
  enabled = 'enabled',
  disabled = 'disabled',
  denied = 'denied',
}

export interface SalesChannel extends BaseModel {
  _id: string;
  type: SalesChannelType;
  name: string;
  userAgent: string;
  companyId: string;
  oauthToken: string;
  oauthTokenSecret: string;
  oauthRefreshToken?: string;
  verified: boolean;
  syncing: boolean;
  status: SalesChannelStatus;
  startSyncDate?: Date;
  lastSyncDate?: Date;
  storeUrl?: string;
  connectionFilters?: {
    band_id: number;
    subdomain: string;
  }[];
  connectionSettings?: {
    client_id: string;
    client_secret: string;
  };
}
