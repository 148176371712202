<div class="{{ textColor }}">
  <!-- 1 -->
  <svg *ngIf="name.length === 1" width="24" height="24" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 52.4V13.2V2H18.8889V6.48H53C54.1046 6.48 55 7.37543 55 8.48V52.4C55 53.8852 54.4147 55.3096 53.3728 56.3598C52.3309 57.41 50.9179 58 49.4444 58H10.5556C9.08213 58 7.66905 57.41 6.62718 56.3598C5.58532 55.3096 5 53.8852 5 52.4Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <!-- 2 -->
  <svg *ngIf="name.length === 2" width="32" height="24" viewBox="0 0 85 58" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 51.4V12.2V1H14.8889V5.48H82C83.1046 5.48 84 6.37543 84 7.48V51.4C84 52.8852 83.4147 54.3096 82.3728 55.3598C81.3309 56.41 79.9179 57 78.4444 57H6.55556C5.08213 57 3.66905 56.41 2.62718 55.3598C1.58532 54.3096 1 52.8852 1 51.4Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <!-- 3 -->
  <svg *ngIf="name.length === 3" width="40" height="24" viewBox="0 0 118 58" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 51.4V12.2V1H14.8889V5.48H115C116.105 5.48 117 6.37543 117 7.48V51.4C117 52.8852 116.415 54.3096 115.373 55.3598C114.331 56.41 112.918 57 111.444 57H6.55556C5.08213 57 3.66905 56.41 2.62718 55.3598C1.58532 54.3096 1 52.8852 1 51.4Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <!-- >= 4 -->
  <svg *ngIf="name.length >= 4" width="48" height="24" viewBox="0 0 181 58" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 51.4V12.2V1H14.8889V5.48H178C179.105 5.48 180 6.37543 180 7.48V51.4C180 52.8852 179.415 54.3096 178.373 55.3598C177.331 56.41 175.918 57 174.444 57H6.55556C5.08213 57 3.66905 56.41 2.62718 55.3598C1.58532 54.3096 1 52.8852 1 51.4Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <span>{{ name }}</span>
</div>
