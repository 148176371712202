import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';
import { User, UserRole } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor() {
    this.init();
  }

  init(): void {
    let config = {};
    if (!environment.production) {
      config = { debug: true, api_host: 'https://api-eu.mixpanel.com' };
    }
    mixpanel.init(environment.mixpanelToken, config);
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user._id) {
      mixpanel.identify(user._id);
    }
  }

  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }

  identify(id: string) {
    mixpanel.identify(id);
  }

  registerAndCreateUserProfile(user: User) {
    if (!user.company) return;

    mixpanel.identify(user._id);
    mixpanel.register({
      Email: user.email,
      Name: user.fullName,
      'Company name': user.company.name,
    });
    mixpanel.people.set({
      $email: user.email,
      $name: user.fullName,
      'Company name': user.company.name,
    });
  }

  register(props: any) {
    mixpanel.register(props);
  }

  registerOnce(props: any) {
    mixpanel.register_once(props);
  }

  peopleSet(props: any) {
    mixpanel.people.set(props);
  }
}
