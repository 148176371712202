import { BaseModel } from './base-model';
import { Company } from './company';
import { UploadScope } from './upload';

export enum UserRole {
  admin = 'admin',
  employee = 'employee',
  superAdmin = 'superAdmin',
}

export const UserRoleTranslations: Record<UserRole, string> = {
  [UserRole.admin]: 'Admin',
  [UserRole.employee]: 'Employee',
  [UserRole.superAdmin]: 'Super Admin',
};

export const UserRoleAll = Object.values(UserRole);

export enum UserStatus {
  enabled = 'enabled',
  disabled = 'disabled',
  invited = 'invited',
}

interface BaseUser extends BaseModel {
  email: string;
  status: UserStatus;
}

export interface InviteUserData {
  email: string;
  fullName: string;
  role: UserRole;
}

export interface UpdateUserData {
  fullName: string;
  currentPassword?: string;
  newPassword?: string;
}

export interface User extends BaseUser {
  fullName: string;
  profilePicture?: string;
  profilePictureUrl?: string;
  role: UserRole;
  companyId: string;
  company?: Company;
}

export enum UserHistoryOrders {
  newOrders = 'newOrders',
  pickedOrders = 'pickedOrders',
  packedOrders = 'packedOrders',
  shippedOrders = 'shippedOrders',
  totalNewOrders = 'totalNewOrders',
  newToShipped = 'newToShipped',
}

export enum UserHistoryOrdersItems {
  newOrds = 'newOrds',
  newOrdsItems = 'newOrdsItems',
  pickedOrds = 'pickedOrds',
  pickedItems = 'pickedItems',
  packedOrds = 'packedOrds',
  packedItems = 'packedItems',
  shippedOrds = 'shippedOrds',
  shippedItems = 'shippedItems',
  totalNewOrds = 'totalNewOrds',
  totalNewItems = 'totalNewItems',
  totalNewPrices = 'totalNewPrices',
}
