import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { InstallShopifyComponent } from './install-shopify/install-shopify.component';
import { SharedModule } from '../shared/shared.module';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { RegisterComponent } from './register/register.component';
import { RegisterShopifyComponent } from './register-shopify/register-shopify.component';
import { LogoComponent } from '../shared/components/logo/logo.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CreateNewPasswordComponent } from './create-new-password/create-new-password.component';
import { EmailSentConfirmationComponent } from './email-sent-confirmation/email-sent-confirmation.component';
import { MatIconModule } from '@angular/material/icon';
import { ConnectDiscogsComponent } from './connect-discogs/connect-discogs.component';
import { ImportOrdersFromSalesChannelComponent } from './import-orders-from-sales-channel/import-orders-from-sales-channel.component';
import { JoinCompanyComponent } from './join-company/join-company-component';
import { MarkOrderShippedComponent } from './mark-order-shipped/mark-order-shipped-component';
import { ImportOrderManualDialogComponent } from './import-orders-from-sales-channel/components/import-order-manual-dialog/import-order-manual-dialog.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { UserDropdownComponent } from './user-dropdown/user-dropdown.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LoginShopifyComponent } from './login-shopify/login-shopify.component';

@NgModule({
  declarations: [
    LoginComponent,
    LoginShopifyComponent,
    InstallShopifyComponent,
    RegisterComponent,
    RegisterShopifyComponent,
    ConnectDiscogsComponent,
    SidenavComponent,
    ToolbarComponent,
    LogoComponent,
    ForgotPasswordComponent,
    CreateNewPasswordComponent,
    JoinCompanyComponent,
    EmailSentConfirmationComponent,
    ImportOrdersFromSalesChannelComponent,
    ImportOrderManualDialogComponent,
    MarkOrderShippedComponent,
    UserDropdownComponent,
  ],
  imports: [CommonModule, SharedModule, MatIconModule, MatMenuModule, MatButtonModule, MatToolbarModule],
  exports: [
    LoginComponent,
    LoginShopifyComponent,
    InstallShopifyComponent,
    SidenavComponent,
    ToolbarComponent,
    LogoComponent,
    ImportOrdersFromSalesChannelComponent,
    MarkOrderShippedComponent,
    ImportOrderManualDialogComponent,
    UserDropdownComponent,
  ],
})
export class CoreModule {}
