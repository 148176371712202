<section>
  <app-logo [withTitle]="true" [graysLevel]="'600'"></app-logo>
  <mat-card>
    <mat-card-title>
      {{ 'register.stepTitle1' | translate }}
    </mat-card-title>
    <mat-card-content>
      <div style="margin-bottom: 30px">
        <label>{{ 'settings.addSalesChannel.shopifyStoreRegisterLabel' | translate }}</label>
      </div>
      <div class="form-group">
        <button mat-flat-button (click)="contact()">
          {{ 'settings.addSalesChannel.buttonContact' | translate }}
        </button>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <div>
        {{ 'register.footerText1' | translate }}<a routerLink="/login">{{ 'register.footerText2' | translate }}</a>
      </div>
    </mat-card-footer>
  </mat-card>
</section>
