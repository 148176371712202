import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
})
export class DateRangeFilterComponent implements OnInit {
  @Input() startField = 'startDate';
  @Input() endField = 'endDate';
  @Input() initializeEmpty: boolean = false;
  @Input() placeholder = 'Select a date range';

  @Input() min: Date;
  @Input() max: Date;
  @Input() dateClass: MatCalendarCellClassFunction<Date>;

  start: Date;
  end: Date;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (!this.initializeEmpty) {
      const now = new Date();
      this.start = new Date(now.getFullYear(), now.getMonth(), 1);
      this.end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      this.dateChanged();
    }

    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((params) => {
      const startStr = params.get(this.startField);
      const endStr = params.get(this.endField);

      const dayjsStart = dayjs(startStr);
      const dayjsEnd = dayjs(endStr);

      this.start = dayjsStart.isValid() ? dayjsStart.toDate() : null;
      this.end = dayjsEnd.isValid() ? dayjsEnd.toDate() : null;
    });
  }

  dateChanged() {
    const start = dayjs(this.start);
    const end = dayjs(this.end);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        [this.startField]: start.isValid() ? start.format('YYYY-MM-DD') : null,
        [this.endField]: end.isValid() ? end.format('YYYY-MM-DD') : null,
      },
      queryParamsHandling: 'merge',
    });
  }

  clear() {
    this.start = null;
    this.end = null;
    this.dateChanged();
  }
}
