import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Image } from '../models/release';
import { EbayProduct } from '../models/ebay-product';

@Injectable({
  providedIn: 'root',
})
export class EbayService {
  constructor(private http: HttpClient) {}

  addNewStore(): Observable<{ oAuthUrl: string }> {
    return this.http.get<any>('auth/ebay/install');
  }

  async getProductImages(productId: string, salesChannelId: string): Promise<Image[]> {
    return this.http.get<Image[]>(`product/ebay/${productId}/images?salesChannelId=${salesChannelId}`).toPromise();
  }

  async getProduct(productId: string, salesChannelId: string): Promise<EbayProduct> {
    return this.http.get<EbayProduct>(`product/ebay/${productId}?salesChannelId=${salesChannelId}`).toPromise();
  }

  authorizeUrl(oauth_url: string) {
    document.location.href = oauth_url;
  }

  changeEbayShippingStatus(
    orderId: string,
    externalOrderId: string,
    salesChannelId: string,
    trackId: string,
    trackUrl?: string,
    shippingCompany?: string,
    message?: string
  ): Observable<{ statusOrder: string }> {
    return this.http.post<{ statusOrder: string }>(`orders/${orderId}/ebay/change-status`, {
      externalOrderId,
      salesChannelId,
      trackId,
      trackUrl,
      shippingCompany,
      message,
    });
  }
}
