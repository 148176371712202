import { Component, ComponentFactoryResolver, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ConfirmationDialogDirective } from './confirmation-dialog.directive';
import { ConfirmationBodyComponent } from './confirmation-body.component';
import { fixDialogFocus } from '../../utils/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  title: string;
  message: string;
  okMessage: string;
  cancelMessage: string;
  component?: any;
  width?: number;

  @ViewChild(ConfirmationDialogDirective, { static: true }) body!: ConfirmationDialogDirective;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = (data || {}).title || 'Attention';
    this.message = (data || {}).message || 'Are you sure you want to perform this action?';
    this.okMessage = (data || {}).okMessage || 'Ok';
    this.cancelMessage = (data || {}).cancelMessage || 'Cancel';
    this.component = (data || {}).component;
    this.width = (data || {}).width || 400;
  }

  ngOnInit() {
    if (this.component) {
      this.loadContent();
    }
    fixDialogFocus(this.dialogRef);
  }

  loadContent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<ConfirmationBodyComponent>(
      this.component.component
    );
    const viewContainerRef = this.body.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<ConfirmationBodyComponent>(componentFactory);
    componentRef.instance.data = this.component.data;
  }
}
