import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-register',
  templateUrl: './register-shopify.component.html',
  styleUrls: ['./register-shopify.component.scss'],
})
export class RegisterShopifyComponent implements OnInit, OnDestroy {
  isLoading: boolean;

  constructor() {}

  contact(): void {
    document.location.href = 'https://www.cratebase.io/contact';
  }

  ngOnInit(): void {}

  ngOnDestroy() {}
}
