import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'dateTimezone',
})
export class DateTimezonePipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(date: any, ...args: any[]): any {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const dateLocale = dayjs(date).tz(this.authService.user.company.timezone).format('DD MMM YYYY, HH:mm:ss');
    return dateLocale;
  }
}
