<div>
  <!-- 1 -->
  <svg
    *ngIf="name.length === 1"
    width="24"
    height="24"
    viewBox="0 0 32 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 1L1 7.8V31.6C1 32.5017 1.35119 33.3665 1.97631 34.0042C2.60143 34.6418 3.44928 35 4.33333 35H27.6667C28.5507 35 29.3986 34.6418 30.0237 34.0042C30.6488 33.3665 31 32.5017 31 31.6V7.8L26 1H6Z"
      stroke="#9CA3AF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M1 8H31" stroke="#9CA3AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
  <!-- 2 -->
  <svg
    *ngIf="name.length === 2"
    width="32"
    height="24"
    viewBox="0 0 44 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 1L1 7.8V31.6C1 32.5017 1.35119 33.3665 1.97631 34.0042C2.60143 34.6418 3.44928 35 4.33333 35H39.6667C40.5507 35 41.3986 34.6418 42.0237 34.0042C42.6488 33.3665 43 32.5017 43 31.6V7.8L38 1H6Z"
      stroke="#9CA3AF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M1 8H42" stroke="#9CA3AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
  <!-- 3 -->
  <svg
    *ngIf="name.length === 3"
    width="40"
    height="24"
    viewBox="0 0 64 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 1L1 7.8V31.6C1 32.5017 1.35119 33.3665 1.97631 34.0042C2.60143 34.6418 3.44928 35 4.33333 35H59.6667C60.5507 35 61.3986 34.6418 62.0237 34.0042C62.6488 33.3665 63 32.5017 63 31.6V7.8L58 1H6Z"
      stroke="#9CA3AF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M1 8H63" stroke="#9CA3AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
  <span class="crate-icon-label">{{ name }}</span>
</div>
