import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'dateTodayYesterday',
})
export class DateTodayYesterdayPipe extends TranslatePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private ref: ChangeDetectorRef,
    private authService: AuthService
  ) {
    super(translateService, ref);
  }

  transform(date: string | Date, ...args: any[]): any {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const userTimezone = this.authService.user.company?.timezone || 'Europe/Amsterdam';

    const day = dayjs(date).tz(userTimezone).format('DD/MM/YY');
    const today = dayjs().tz(userTimezone).format('DD/MM/YY');
    const yesterday = dayjs().subtract(1, 'day').tz(userTimezone).format('DD/MM/YY');
    const time = dayjs(date).tz(userTimezone).format('HH:mm');
    if (day === today) {
      return `${super.transform('today')} ${time}`;
    } else if (day === yesterday) {
      return `${super.transform('yesterday')} ${time}`;
    } else {
      return dayjs(date).tz(userTimezone).format('DD/MM/YY HH:mm');
    }
  }
}
