import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { take } from 'rxjs/operators';

export const fixDialogFocus = (dialogRef: MatDialogRef<any>) => {
  if (event && event.currentTarget && (<any>event.currentTarget).blur) {
    const initiator: any = event.currentTarget;

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        setTimeout(() => {
          try {
            initiator.blur();
          } catch {}
        }, 0);
      });
  }
};
