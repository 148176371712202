import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnChanges {
  @Input() error: any;
  @Input() margin = true; // Optional - specify if the component should have a margin-bottom of 1 rem or not

  errorMessage: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.handleError(changes.error.currentValue);
  }

  handleError(error: any) {
    if (error == null) return;
    if (typeof error === 'string') {
      this.errorMessage = error;
    }
    if (typeof error === 'object') {
      this.errorMessage = (error.error || {}).message || error.message;
    }
  }

  @HostBinding('class.hidden')
  get isHidden() {
    return !this.error || !this.errorMessage;
  }
}
