import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { RoleGuard } from './shared/guards/role.guard';
import { RegisterComponent } from './core/register/register.component';
import { RegisterShopifyComponent } from './core/register-shopify/register-shopify.component';
import { ConnectDiscogsComponent } from './core/connect-discogs/connect-discogs.component';
import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { CreateNewPasswordComponent } from './core/create-new-password/create-new-password.component';
import { EmailSentConfirmationComponent } from './core/email-sent-confirmation/email-sent-confirmation.component';
import { NoAuthGuard } from './shared/guards/no-auth.guard';
import { JoinCompanyComponent } from './core/join-company/join-company-component';
import { UserRole, UserRoleAll } from './shared/models/user';
import { LoginShopifyComponent } from './core/login-shopify/login-shopify.component';
import { InstallShopifyComponent } from './core/install-shopify/install-shopify.component';
import { ShopifyGuard } from './shared/guards/shopify.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'admin/companies',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'login-shopify',
    component: LoginShopifyComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'shopify',
    component: InstallShopifyComponent,
    canActivate: [ShopifyGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'register-shopify',
    component: RegisterShopifyComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'reset-password/:token',
    component: CreateNewPasswordComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'join-company/:token',
    component: JoinCompanyComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'reset-password-sent',
    component: EmailSentConfirmationComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'connect-discogs',
    component: ConnectDiscogsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: [UserRole.admin, UserRole.employee] },
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'orders',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    loadChildren: () => import('./order/order.module').then((m) => m.OrderModule),
  },
  {
    path: 'picking',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    loadChildren: () => import('./picking/picking.module').then((m) => m.PickingModule),
  },
  {
    path: 'packing',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    loadChildren: () => import('./packing/packing.module').then((m) => m.PackingModule),
  },
  {
    path: 'shipments',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    loadChildren: () => import('./shipments/shipments.module').then((m) => m.ShipmentsModule),
  },
  {
    path: 'waybills',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    loadChildren: () => import('./waybill/waybill.module').then((m) => m.WaybillModule),
  },
  {
    path: 'shipping',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    loadChildren: () => import('./shipping/shipping.module').then((m) => m.ShippingModule),
  },
  {
    path: 'archive',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    loadChildren: () => import('./archive/archive.module').then((m) => m.ArchiveModule),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'forum',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    loadChildren: () => import('./forum/forum.module').then((m) => m.ForumModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: [UserRole.superAdmin] },
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
