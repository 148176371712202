import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { Order, GroupedOrders, OrderStatus, StatisticsOrders, PickingStatus } from '../models/order';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends CrudService<Order> {
  constructor(http: HttpClient) {
    super(http, 'orders');
  }

  async getOrdersInfo(picking = false) {
    return this.http.get<GroupedOrders[]>(`${this.endpoint}/info/all`, { params: { picking } }).toPromise();
  }

  async getOrdersStatistics(startDate: string, endDate: string, status: OrderStatus, userId?: string) {
    return this.http
      .get<StatisticsOrders>(`${this.endpoint}/history/statistics`, {
        params: {
          startDate,
          endDate,
          status,
          ...(userId && { userId }),
        },
      })
      .toPromise();
  }

  async getOrdersStatisticsPrices(startDate: string, endDate: string, salesChannelId?: string) {
    return this.http
      .get<any>(`${this.endpoint}/history/statistics/prices`, {
        params: {
          startDate,
          endDate,
          ...(salesChannelId && { salesChannelId }),
        },
      })
      .toPromise();
  }

  async getDailySales(startDate: string, endDate: string, saleChannelId: string): Promise<any[]> {
    const result = await this.http
      .get<any>(`${this.endpoint}/order/daily-sales`, {
        params: {
          startDate,
          endDate,
          ...(saleChannelId && { saleChannelId }),
        },
      })
      .toPromise();
    return result;
  }

  async importManualOrder(data: ImportOrderManualData) {
    return this.http.post<Order>(`${this.endpoint}/manual-import`, data).toPromise();
  }

  async updateOrderItem(orderId: string, data: any) {
    await this.http.put<any>(`${this.endpoint}/update-item/${orderId}`, data).toPromise();
  }
  async changeEbayShippingStatus(id: string, track: string): Promise<any> {
    const response = await this.http.post<any>(`${this.endpoint}/change-ebaystatus/${id}`, { track }).toPromise();
    return response;
  }
  async updateManyOrders(orderIds: string[], data: Partial<Order>) {
    const result = await this.http
      .post<GroupedOrders[]>(`${this.endpoint}/bulk-update`, { data, orderIds })
      .toPromise();
    this.dataChangedSubject.next(true);
    return result;
  }

  async addNote(orderId: string, text: string) {
    return this.http.post<Order>(`${this.endpoint}/${orderId}/notes`, { text }).toPromise();
  }

  async updateOrderItemsStatus(orderId: string, status: PickingStatus) {
    await this.http.put<any>(`${this.endpoint}/update-items-status/${orderId}`, { pickingStatus: status }).toPromise();
  }
}

export type ImportOrderManualData = {
  salesChannelId: string;
  externalOrderId: string;
};
