<app-dialog [title]="'orders.manualImport.dialogTitle' | translate" (closeEmitter)="close(false)">
  <div class="shipping-carrier-details">
    <div class="shipping-carrier-info">
      <mat-label>{{ 'orders.manualImport.externalOrderId' | translate }}</mat-label>
      <input [(ngModel)]="externalOrderId" [placeholder]="'orders.manualImport.externalOrderIdInput' | translate" />
    </div>
    <div class="shipping-carrier-info">
      <mat-label>{{ 'orders.manualImport.salesChannelId' | translate }}</mat-label>
      <ng-select
        id="salesChannel"
        name="salesChannel"
        bindLabel="name"
        bindValue="_id"
        [items]="salesChannels"
        [(ngModel)]="salesChannelId"
        [multiple]="false"
        [clearable]="false"
      ></ng-select>
    </div>
  </div>
  <div class="form-group">
    <button mat-flat-button class="submit-button" (click)="close(true)">
      {{ 'orders.manualImport.buttonSubmit' | translate }}
    </button>
  </div>
</app-dialog>
