import { Injectable } from '@angular/core';
import { SalesChannelService } from './sales-channel.service';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import * as dayjs from 'dayjs';
import { Message, MessageType } from '../models/message';
import { HttpClient } from '@angular/common/http';
import { DiscogsOrder, DiscogsOrderStatus } from '../models/order';
import { Release } from '../models/release';

@Injectable({
  providedIn: 'root',
})
export class DiscogsApiService {
  constructor(
    private salesChannelService: SalesChannelService,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  async getRelease(releaseId: string, salesChannelId: string) {
    const salesChannelCredentials = await this.salesChannelService.getCredentials(salesChannelId);
    const auth = await this.buildAuth(salesChannelCredentials.token, salesChannelCredentials.tokenSecret);
    return await this.http
      .get<Release>(`https://api.discogs.com/releases/${releaseId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: auth,
          'User-Agent': environment.userAgent,
        },
      })
      .toPromise();
  }

  async getMessages(externalId: string, salesChannelId: string) {
    const timestamp = sessionStorage.getItem(`${externalId}-messages-timestamp`);
    if (dayjs().diff(dayjs(timestamp), 'seconds') < 10) {
      // cache for 10 seconds
      return JSON.parse(sessionStorage.getItem(`${externalId}-messages`));
    } else {
      let messages = await this.getAllMessages(externalId, salesChannelId);
      messages = messages.filter((item) => item && item.type === MessageType.message);
      sessionStorage.setItem(`${externalId}-messages`, JSON.stringify(messages));
      sessionStorage.setItem(`${externalId}-messages-timestamp`, new Date().toString());
      return messages;
    }
  }

  async getTimeline(externalId: string, salesChannelId: string) {
    const timestamp = sessionStorage.getItem(`${externalId}-timeline-timestamp`);
    if (dayjs().diff(dayjs(timestamp), 'seconds') < 10) {
      // cache for 10 seconds
      return JSON.parse(sessionStorage.getItem(`${externalId}-timeline`));
    } else {
      let messages = await this.getAllMessages(externalId, salesChannelId);
      messages = messages.filter((item) => item && item.type === MessageType.status);
      sessionStorage.setItem(`${externalId}-timeline`, JSON.stringify(messages));
      sessionStorage.setItem(`${externalId}-timeline-timestamp`, new Date().toString());
      return messages;
    }
  }

  async getAllMessages(externalId, salesChannelId) {
    const salesChannelCredentials = await this.salesChannelService.getCredentials(salesChannelId);
    const auth = await this.buildAuth(salesChannelCredentials.token, salesChannelCredentials.tokenSecret);

    const res = await this.http
      .get<{ messages: Message[] }>(`https://api.discogs.com/marketplace/orders/${externalId}/messages`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: auth,
          'User-Agent': environment.userAgent,
        },
      })
      .toPromise();
    return res.messages;
  }

  async getOrder(externalId: string, salesChannelId: string) {
    const salesChannelCredentials = await this.salesChannelService.getCredentials(salesChannelId);
    const auth = await this.buildAuth(salesChannelCredentials.token, salesChannelCredentials.tokenSecret);
    const order = await this.http
      .get<DiscogsOrder>(`https://api.discogs.com/marketplace/orders/${externalId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: auth,
          'User-Agent': environment.userAgent,
        },
      })
      .toPromise();
    return order;
  }

  public async buildAuth(token: string, tokenSecret: string) {
    const { discogsConsumerKey, discogsConsumerSecret } = await this.authService.getDiscogsCredentials();
    return (
      `OAuth oauth_consumer_key="${discogsConsumerKey}",` +
      `oauth_token="${token}",oauth_token_secret="${tokenSecret}",` +
      `oauth_signature_method="PLAINTEXT",` +
      `oauth_timestamp="${Date.now().toString()}",` +
      `oauth_nonce="${Date.now().toString()}",` +
      `oauth_version="1.0",` +
      `oauth_signature="${discogsConsumerSecret}&${tokenSecret}"`
    );
  }
}
