<section>
  <app-logo [withTitle]="true" [graysLevel]="'600'"></app-logo>
  <mat-card>
    <mat-card-title>{{ 'forgotPassword.title' | translate }}</mat-card-title>
    <mat-card-content>
      <form #forgotPasswordForm="ngForm" (ngSubmit)="forgotPassword()">
        <div class="details">
          {{ 'forgotPassword.details' | translate }}
        </div>
        <div class="form-group">
          <label for="email">{{ 'forgotPassword.emailLabel' | translate }}</label>
          <input
            [(ngModel)]="email"
            type="email"
            id="email"
            name="email"
            [placeholder]="'forgotPassword.emailInput' | translate"
            (keyup.enter)="forgotPassword()"
            autocomplete="email"
            required
            #emailModel="ngModel"
          />
          <app-input-error>{{ 'forgotPassword.emailError' | translate }}</app-input-error>
        </div>
        <div class="form-group">
          <button
            mat-flat-button
            color="primary"
            (click)="forgotPassword()"
            [disabled]="emailModel.invalid"
            [appLoading]="isLoading"
          >
            {{ 'forgotPassword.buttonReset' | translate }}
          </button>
        </div>
      </form>
    </mat-card-content>
    <mat-card-footer>
      <div>
        {{ 'forgotPassword.footerText1' | translate
        }}<a routerLink="/login">{{ 'forgotPassword.footerText2' | translate }}</a>
      </div>
    </mat-card-footer>
  </mat-card>
</section>
