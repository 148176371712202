<div class="import-orders-holder" [appLoading]="isLoading">
  <section class="form-group">
    <button mat-flat-button (click)="startSyncSalesChannel()" [disabled]="isLoading">
      <img *ngIf="isMobile" src="/assets/icons/import.svg" />
      {{
        (isMobile ? 'navbar.importOrdersButtonShort' : 'navbar.import' + (isLoading ? 'ing' : '') + 'OrdersButton')
          | translate
      }}
    </button>

    <button mat-flat-button (click)="openImportManualOrderModal()" [disabled]="isLoading">
      <img *ngIf="isMobile" src="/assets/icons/import.svg" />
      {{
        (isMobile
          ? 'navbar.importManualOrderButtonShort'
          : 'navbar.import' + (isLoading ? 'ing' : '') + 'ManualOrderButton'
        ) | translate
      }}
    </button>
  </section>
</div>
