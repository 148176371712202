<header *ngIf="isLoggedIn" [ngClass]="{ 'sidenav-closed': condensed }">
  <app-toolbar [isMobile]="isMobile" (menuToggle)="toggleSidenav()" [url]="url"> </app-toolbar>
</header>

<div class="backdrop" *ngIf="isMobile && sidenavOpened"></div>

<aside *ngIf="isLoggedIn || isMobile" [class.opened]="sidenavOpened" (click)="toggleSidenav()">
  <app-sidenav
    [isMobile]="isMobile"
    [isCondensed]="condensed"
    (menuToggle)="toggleSidenav()"
    (logout)="sidenavOpened = false"
    (click)="$event.stopPropagation()"
  ></app-sidenav>
</aside>

<main>
  <router-outlet></router-outlet>
</main>
