import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AddBandcampStoreResponse, BandcampAccountResponse } from '../models/bandcamp-model';

@Injectable({
  providedIn: 'root',
})
export class BandcampService {
  constructor(private http: HttpClient) {}

  addStore(data: { client_id: string; client_secret: string }): Observable<AddBandcampStoreResponse> {
    const endPoint = 'auth/bandcamp/login';

    return this.http.post<AddBandcampStoreResponse>(endPoint, { ...data });
  }

  editStore(salesChannelId: string, data: any): Observable<{ success: true }> {
    const endPoint = 'bandcamp/edit';

    return this.http.post<{ success: true }>(endPoint, { salesChannelId, ...data });
  }

  async getBands(salesChannelId: string): Promise<BandcampAccountResponse> {
    const endPoint = 'bandcamp/bands';

    return this.http.post<BandcampAccountResponse>(endPoint, { salesChannelId }).toPromise();
  }

  async startFirstSync(salesChannelId: string): Promise<{ name: string }> {
    const endPoint = 'bandcamp/start-first-sync';

    return this.http.post<{ name: string }>(endPoint, { salesChannelId }).toPromise();
  }

  changeShippingStatus(
    orderId: string,
    externalOrderId: string,
    salesChannelId: string,
    trackId: string,
    shippingCompany?: string,
    message?: string
  ): Observable<{ statusOrder: string }> {
    return this.http.post<{ statusOrder: string }>(`orders/${orderId}/bandcamp/change-status`, {
      externalOrderId,
      salesChannelId,
      trackId,
      shippingCompany,
      message,
    });
  }
}
