import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NasdiscApiService {
  constructor(private http: HttpClient) {}

  async changeOrderStatus(salesChannelId: string, trackingId: string, externalOrderId: string, trackingType: string) {
    return await this.http
      .put(`nasdisc/tracking`, {
        salesChannelId,
        trackingId,
        externalOrderId,
        trackingType,
      })
      .toPromise();
  }
}
