<ng-select
  class="custom"
  [items]="options"
  [placeholder]="placeholder"
  [(ngModel)]="values"
  (ngModelChange)="valueChanged($event)"
  [multiple]="multiple"
  [bindLabel]="null"
  [bindValue]="null"
  [closeOnSelect]="!multiple"
  [searchable]="false"
  dropdownPosition="bottom"
  [maxSelectedItems]="maxNumberSelect"
  #select
>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-select-multiple-label">
      <span class="d-b2">{{ items.length }} {{ outputField }}{{ items.length > 1 ? 's' : '' }} selected</span>
    </div>
  </ng-template>

  <ng-template class="custom-icon" *ngIf="customIcon" ng-option-tmp let-item="item">
    <img style="margin-right: 5px" height="25" width="25" [src]="item.thumbnail" />
    <span>{{ item.label }}</span>
  </ng-template>
</ng-select>
