import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../utils/string.utils';

@Pipe({
  name: 'uncapitalizeFirstLetter',
})
export class UncapitalizeFirstLetterPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return value.trim();
  }
}
