<section>
  <app-logo [withTitle]="true" [graysLevel]="'600'"></app-logo>
  <mat-card>
    <mat-card-title>
      <img src="/assets/icons/email.svg" />
      {{ 'resetPasswordSent.title' | translate }}
    </mat-card-title>
    <mat-card-content>
      <div class="details">{{ 'resetPasswordSent.details' | translate }}</div>
    </mat-card-content>
  </mat-card>

  <app-error [error]="error"></app-error>
</section>
