import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-separator-icon',
  templateUrl: './separator-icon.component.html',
  styleUrls: ['./separator-icon.component.scss'],
})
export class SeparatorIconComponent implements OnInit {
  @Input() textColor: 'green-800' | 'grays-400' | 'grays-800' | 'yellow-500' | 'white' = 'white';
  @Input() name: string;

  constructor() {}

  ngOnInit(): void {}
}
