import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Order, OrderStatus } from '../../../shared/models/order';
import { OrderService } from '../../../shared/services/order.service';

@UntilDestroy()
@Component({
  selector: 'app-buttons-filter',
  templateUrl: './buttons-filter.component.html',
  styleUrls: ['./buttons-filter.component.scss'],
})
export class ButtonsFilterComponent implements OnInit {
  @Input() ordersInfo: {
    [key: string]: { itemsCount: number; ordersCount: number; ordersFlaggedCount: number; itemsFlaggedCount: number };
  };
  @Output() fetchOrdersInfo = new EventEmitter();
  selectedOrderStatus: OrderStatus | 'All' | 'Flagged' = 'All';
  allOrders: Order[] = [];
  OrderStatus = OrderStatus;

  constructor(private route: ActivatedRoute, private router: Router, private orderService: OrderService) {}

  ngOnInit() {
    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((params) => {
      this.selectedOrderStatus = <OrderStatus>params.get('status') || (params.has('flagged') ? 'Flagged' : 'All');
    });
  }

  async filter(status: OrderStatus | 'All', flagged: boolean = false) {
    this.selectedOrderStatus = flagged ? 'Flagged' : status;
    const queryParams = {
      status: status === 'All' ? null : status,
      start: 0,
      flagged: flagged ? true : null,
    };
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });
    this.fetchOrdersInfo.emit();
  }
}
