import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SalesChannel } from 'src/app/shared/models/sales-channel';
import { ImportOrderManualData } from 'src/app/shared/services/order.service';
import { SalesChannelService } from 'src/app/shared/services/sales-channel.service';
import { fixDialogFocus } from '../../../../shared/utils/dialog';

@UntilDestroy()
@Component({
  selector: 'import-order-manual-dialog',
  templateUrl: './import-order-manual-dialog.component.html',
  styleUrls: ['./import-order-manual-dialog.component.scss'],
})
export class ImportOrderManualDialogComponent implements OnInit {
  salesChannelId: string | null = null;
  externalOrderId: string;

  salesChannels: SalesChannel[] = [];
  constructor(
    public dialogRef: MatDialogRef<ImportOrderManualDialogComponent, ImportOrderManualData>,
    public dialog: MatDialog,
    private salesChannelService: SalesChannelService
  ) {}

  async ngOnInit() {
    fixDialogFocus(this.dialogRef);
    this.salesChannels = await this.salesChannelService.getMySalesChannelsAvailable();
  }

  close(save: boolean) {
    if (save) {
      this.dialogRef.close({
        salesChannelId: this.salesChannelId,
        externalOrderId: this.externalOrderId,
      });
    } else {
      this.dialogRef.close();
    }
  }
}
