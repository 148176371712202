<div class="filter-row" *ngIf="ordersInfo && ordersInfo['All']">
  <!--  ALL  -->
  <div class="filter-btn all" [class.active]="selectedOrderStatus === 'All'" (click)="filter('All')">
    <div>
      <span class="status">{{ 'orders.buttonFilter.all' | translate }} </span>
      <span class="orders-number">{{ ordersInfo['All'].ordersCount }}</span>
    </div>
    <div>
      <span class="items-number">
        {{ 'orders.buttonFilter.items' | translate: { count: ordersInfo['All'].itemsCount } }}
      </span>
    </div>
  </div>

  <!--  ON HOLD  -->
  <div
    class="filter-btn onHold"
    [class.active]="selectedOrderStatus === OrderStatus.onHold"
    (click)="filter(OrderStatus.onHold)"
  >
    <div>
      <span class="status">{{ 'orders.buttonFilter.onHold' | translate }} </span>
      <span class="orders-number">{{ ordersInfo[OrderStatus.onHold].ordersCount }}</span>
    </div>
    <div>
      <span class="items-number">
        {{ 'orders.buttonFilter.items' | translate: { count: ordersInfo[OrderStatus.onHold].itemsCount } }}
      </span>
    </div>
  </div>

  <!--  NEW ORDER  -->
  <div
    class="filter-btn new flow"
    [class.active]="selectedOrderStatus === OrderStatus.newOrder"
    (click)="filter(OrderStatus.newOrder)"
  >
    <div>
      <span class="status">{{ 'orders.buttonFilter.new' | translate }} </span>
      <span class="orders-number">{{ ordersInfo[OrderStatus.newOrder].ordersCount }}</span>
    </div>
    <div>
      <span class="items-number">
        {{ 'orders.buttonFilter.items' | translate: { count: ordersInfo[OrderStatus.newOrder].itemsCount } }}
      </span>
    </div>
  </div>
  <div class="bar"></div>

  <!--  PICKED  -->
  <div
    class="filter-btn picked flow"
    [class.active]="selectedOrderStatus === OrderStatus.picked"
    (click)="filter(OrderStatus.picked)"
  >
    <div>
      <span class="status">{{ 'orders.buttonFilter.picked' | translate }} </span>
      <span class="orders-number">{{ ordersInfo[OrderStatus.picked].ordersCount }}</span>
    </div>
    <div>
      <span class="items-number">
        {{ 'orders.buttonFilter.items' | translate: { count: ordersInfo[OrderStatus.picked].itemsCount } }}
      </span>
    </div>
  </div>
  <div class="bar"></div>

  <!--  PACKED  -->
  <div
    class="filter-btn packed flow"
    [class.active]="selectedOrderStatus === OrderStatus.packed"
    (click)="filter(OrderStatus.packed)"
  >
    <div>
      <span class="status">{{ 'orders.buttonFilter.packed' | translate }} </span>
      <span class="orders-number">{{ ordersInfo[OrderStatus.packed].ordersCount }}</span>
    </div>
    <div>
      <span class="items-number">
        {{ 'orders.buttonFilter.items' | translate: { count: ordersInfo[OrderStatus.packed].itemsCount } }}
      </span>
    </div>
  </div>
  <div class="bar"></div>

  <!--  SHIPPED  -->
  <div
    class="filter-btn shipped flow"
    [class.active]="selectedOrderStatus === OrderStatus.shipped"
    (click)="filter(OrderStatus.shipped)"
  >
    <div>
      <span class="status">{{ 'orders.buttonFilter.shipped' | translate }} </span>
      <span class="orders-number">{{ ordersInfo[OrderStatus.shipped].ordersCount }}</span>
    </div>
    <div>
      <span class="items-number">
        {{ 'orders.buttonFilter.items' | translate: { count: ordersInfo[OrderStatus.shipped].itemsCount } }}
      </span>
    </div>
  </div>

  <!--  PICK UP  -->
  <div
    class="filter-btn pickUp"
    [class.active]="selectedOrderStatus === OrderStatus.pickUp"
    (click)="filter(OrderStatus.pickUp)"
  >
    <div>
      <span class="status">{{ 'orders.buttonFilter.pickUp' | translate }} </span>
      <span class="orders-number">{{ ordersInfo[OrderStatus.pickUp].ordersCount }}</span>
    </div>
    <div>
      <span class="items-number">
        {{ 'orders.buttonFilter.items' | translate: { count: ordersInfo[OrderStatus.pickUp].itemsCount } }}
      </span>
    </div>
  </div>

  <!--  FLAGGED  -->
  <div class="filter-btn flagged" [class.active]="selectedOrderStatus === 'Flagged'" (click)="filter('All', true)">
    <div>
      <span class="status">{{ 'orders.buttonFilter.flagged' | translate }} </span>
      <span class="orders-number">{{ ordersInfo['All'].ordersFlaggedCount }}</span>
    </div>
    <div>
      <span class="items-number">
        {{ 'orders.buttonFilter.items' | translate: { count: ordersInfo['All'].itemsFlaggedCount } }}
      </span>
    </div>
  </div>

  <ng-content></ng-content>
</div>
