<div [style.width.px]="width">
  <div class="top-row">
    <div [class]="withClose ? 'left' : 'left centered'">
      <img *ngIf="icon" [src]="'/assets/icons/' + icon + '.svg'" />
      <span>{{ title }}</span>
    </div>
    <img src="/assets/icons/close.svg" class="close" *ngIf="withClose" (click)="close()" />
  </div>
  <ng-content></ng-content>
</div>
