import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message } from '../models/message';
import { DiscogsOrderStatus } from '../models/order';

@Injectable({
  providedIn: 'root',
})
export class DiscogsService {
  constructor(private http: HttpClient) {}

  async requestToken(callbackSlug: string): Promise<{ oauth_token: string }> {
    return this.http.post<{ oauth_token: string; oauth_secret_token }>('auth/discogs', { callbackSlug }).toPromise();
  }

  async validateToken(token: string, verifier: string): Promise<{ salesChannelId: string; salesChannelName: string }> {
    return this.http
      .post<{ salesChannelId: string; salesChannelName: string }>('auth/discogs/validate', { token, verifier })
      .toPromise();
  }

  authorizeUrl(token: string) {
    document.location.href = `https://discogs.com/oauth/authorize?oauth_token=${token}`;
  }

  async sendMessage(externalOrderId: string, salesChannelId: string, message: string) {
    sessionStorage.removeItem(`${externalOrderId}-messages-timestamp`); // refresh cache
    return this.http.post<Message>('discogs/send-message', { externalOrderId, salesChannelId, message }).toPromise();
  }

  async changeOrderStatus(
    externalOrderId: string,
    salesChannelId: string,
    status: DiscogsOrderStatus,
    message?: string
  ) {
    return this.http
      .post<Message>('discogs/change-order-status', { externalOrderId, salesChannelId, status, message })
      .toPromise();
  }
}
