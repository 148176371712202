import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  constructor() {}

  @Input() title: string;
  @Input() width: number = 400;
  @Input() withClose = true;
  @Input() icon?: string;
  @Output() closeEmitter? = new EventEmitter<any>();

  ngOnInit(): void {}

  close() {
    this.closeEmitter.emit();
  }
}
