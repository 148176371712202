import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../shared/services/toast.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ShopifyService } from '../../shared/services/shopify.service';
import { User, UserRole } from '../../shared/models/user';
import { SalesChannelService } from 'src/app/shared/services/sales-channel.service';

@UntilDestroy()
@Component({
  selector: 'app-install-shopify',
  templateUrl: './install-shopify.component.html',
  styleUrls: ['./install-shopify.component.scss'],
})
export class InstallShopifyComponent implements OnInit {
  host: string;
  hmac: string;
  shop: string;
  store: string;
  timestamp: string;
  user: User;
  isLoading: boolean;
  error: any;
  showAdminConfirmation: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private shopifyService: ShopifyService
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(async (params) => {
      const { hmac, host, shop, timestamp } = params;
      this.host = host;
      this.hmac = hmac;
      this.shop = shop;
      this.timestamp = timestamp;
    });
    this.user = this.authService.user;

    if (!this.host || !this.hmac || !this.shop) {
      this.router.navigate(['/orders']);
    }
    if (this.shop) {
      this.store = this.shop.replace('.myshopify.com', '');
    }

    await this.install();
  }

  async install() {
    if (this.isLoading) return;

    try {
      this.error = null;
      this.isLoading = true;

      const res = await this.authService.installShopify(
        this.hmac,
        this.host,
        this.shop,
        this.timestamp,
        this.user?.email
      );
      this.shopifyService.authorizeUrl(res);
    } catch (e) {
      this.toast.showError(e, false, '', true);
    } finally {
      this.isLoading = false;
    }
  }
}
