import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Company } from '../../shared/models/company';
import { SuperAdminCompanyService } from '../../shared/services/super-admin-company.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnChanges {
  @Input() isMobile = false;
  @Input() url: string = '';

  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>();

  title: string = '';
  canGoBack: boolean = false;
  withTitle: boolean = true;
  company: Company;

  constructor(private superAdminCompanyService: SuperAdminCompanyService) {}

  ngOnInit(): void {}

  async ngOnChanges(changes: SimpleChanges) {
    this.canGoBack = this.url.includes('/order/');
    this.withTitle = !this.canGoBack;
    const urlParts = this.url.split('/');
    this.title = urlParts[1].split('?')[0];

    if (this.title === 'admin') {
      this.title = urlParts[urlParts.length - 1].split('?')[0];
      const companyId = urlParts[urlParts.length - 2];

      if (
        this.title !== 'companies' &&
        this.title !== 'shipping-rates-management' &&
        (!this.company || this.company._id !== companyId)
      ) {
        const company = await this.superAdminCompanyService.getOne(companyId);
        this.company = company;
        this.canGoBack = true;
      }
      this.canGoBack = this.withTitle = this.title !== 'admin';
    }
  }
}
