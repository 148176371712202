import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { DiscogsService } from '../../shared/services/discogs.service';
import { ToastService } from '../../shared/services/toast.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SalesChannelService } from '../../shared/services/sales-channel.service';
import { SalesChannelType } from '../../shared/models/sales-channel';
import { MixpanelService } from '../../shared/services/mixpanel.service';

@UntilDestroy()
@Component({
  selector: 'app-register',
  templateUrl: './connect-discogs.component.html',
  styleUrls: ['./connect-discogs.component.scss'],
})
export class ConnectDiscogsComponent implements OnInit, OnDestroy {
  isLoading = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private discogsService: DiscogsService,
    private toast: ToastService,
    private salesChannelService: SalesChannelService,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(async (params) => {
      const { oauth_token, oauth_verifier, denied } = params;
      try {
        if (oauth_verifier && oauth_token) {
          const { salesChannelId, salesChannelName } = await this.discogsService.validateToken(
            oauth_token,
            oauth_verifier
          );
          this.toast.showSuccess('toast.success.addedSalesChannel');
          this.mixpanelService.track('Sales channel connected', {
            'Channel type': SalesChannelType.discogs,
            'Channel name': salesChannelName,
            'Channel id': salesChannelId,
          });
          this.router.navigate(['/']);
        }
        if (denied) {
          await this.salesChannelService.deleteByOAuthToken(denied);
        }
      } catch (e) {
        this.toast.showError(e, false, '', true);
      } finally {
        this.isLoading = false;
      }
    });
  }

  async connect() {
    try {
      this.isLoading = true;
      const response = await this.discogsService.requestToken('/connect-discogs');
      if (response.oauth_token) {
        this.discogsService.authorizeUrl(response.oauth_token);
      } else {
        this.toast.showError('toast.error.requestAuthToken', true, '', true);
      }
    } catch (e) {
      this.toast.showError(e, false, '', true);
      this.isLoading = false;
    } finally {
    }
  }

  ngOnDestroy() {
    this.toast.onDismiss();
  }
}
