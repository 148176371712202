import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { ToastService } from '../../shared/services/toast.service';
import { MixpanelService } from '../../shared/services/mixpanel.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  user = {
    fullName: '',
    email: '',
    password: '',
  };
  company: {
    name: string;
    phone?: string;
    vat?: string;
  };

  isLoading: boolean;
  hide = true;
  error: any;
  currentStep: number = 0;
  agreeTerms = false;
  stepTitles = ['register.stepTitle1', 'register.stepTitle2'];

  constructor(
    private authService: AuthService,
    private router: Router,
    protected route: ActivatedRoute,
    private toast: ToastService,
    private mixpanelService: MixpanelService
  ) {}

  protected getRoute(): ActivatedRoute {
    return this.route;
  }

  protected getAuthService(): AuthService {
    return this.authService;
  }

  protected getRouter(): Router {
    return this.router;
  }

  protected getToastService(): ToastService {
    return this.toast;
  }

  protected getMixpanelService(): MixpanelService {
    return this.mixpanelService;
  }

  ngOnInit(): void {
    this.company = { name: '' };
  }

  async nextStep() {
    try {
      this.error = null;
      this.isLoading = true;
      switch (this.currentStep) {
        case 0:
          await this.authService.validateEmail(this.user.email);
          break;
      }
      this.currentStep++;
    } catch (e) {
      this.toast.showError(e, false, '', true);
    } finally {
      this.isLoading = false;
    }
  }

  async register() {
    if (this.isLoading) return;
    try {
      this.error = null;
      this.isLoading = true;
      if (this.company.phone === '') delete this.company.phone;
      const user = await this.authService.register(this.user, this.company);
      this.mixpanelService.registerAndCreateUserProfile(user);
      const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/connect-discogs';
      this.router.navigate([returnUrl]);
    } catch (e) {
      this.toast.showError(e, false, '', true);
    } finally {
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.toast.onDismiss();
  }
}
