import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SalesChannel } from '../models/sales-channel';
import { CrudService } from './crud.service';

export interface SyncStatus {
  syncing: boolean;
  lastSyncDate?: Date;
  lastSyncOrdersCount?: number;
  channelNames?: string[];
}

export interface SyncResponse {
  success: string[];
  failed: string[];
}

@Injectable({
  providedIn: 'root',
})
export class SalesChannelService extends CrudService<SalesChannel> {
  constructor(http: HttpClient) {
    super(http, 'sales-channels');
  }

  public getMySalesChannels() {
    return this.http.get<SalesChannel[]>(`${this.endpoint}/`).toPromise();
  }

  public getMySalesChannelsAvailable() {
    return this.http.get<SalesChannel[]>(`${this.endpoint}/available`).toPromise();
  }

  public getMySalesChannelsOnlyDiscogs() {
    return this.http.get<SalesChannel[]>(`${this.endpoint}/only-discogs`).toPromise();
  }

  async syncStatus(): Promise<SyncStatus> {
    return this.http.get<SyncStatus>(`${this.endpoint}/sync-status`).toPromise();
  }

  public getByStoreUrlShopify(storeUrl: string) {
    return this.http.post<boolean>(`${this.endpoint}/shopify-existence`, { storeUrl }).toPromise();
  }

  async deleteByOAuthToken(oauthToken: string): Promise<SalesChannel> {
    return this.http.delete<SalesChannel>(`${this.endpoint}/denied-oauth/${oauthToken}`, {}).toPromise();
  }

  async getCredentials(salesChannelId: string): Promise<{ token: string; tokenSecret: string }> {
    try {
      const { token, tokenSecret } = JSON.parse(localStorage.getItem(`${salesChannelId}-credentials`));
      return { token, tokenSecret };
    } catch (e) {
      const credentials = await this.http
        .get<{ token: string; tokenSecret: string }>(`${this.endpoint}/${salesChannelId}/discogs-credentials`)
        .toPromise();
      localStorage.setItem(`${salesChannelId}-credentials`, JSON.stringify(credentials));
      return credentials;
    }
  }

  async startSync() {
    return this.http.post<SyncResponse>(`${this.endpoint}/start-sync`, {}).toPromise();
  }
}
