<div class="scrollable-container">
  <nav [class.condensed]="condensed">
    <app-logo *ngIf="!isMobile" [withTitle]="!isCondensed" [graysLevel]="'900'"></app-logo>
    <button mat-button class="close-icon" (click)="menuToggle.emit()" *ngIf="isMobile">
      <i class="icon">close</i>
    </button>

    <section class="navigation">
      <div *ngFor="let item of menuItems; index as i" class="menu-item" [class.expanded]="item.expanded">
        <!-- Menu is not condensed or item does not have a submenu -->
        <ng-container *ngIf="!item.submenu || !condensed">
          <button
            class="menu-button"
            mat-button
            (click)="itemClick(item)"
            [routerLink]="item.link || null"
            [routerLinkActive]="item.link ? 'active' : ''"
            [queryParamsHandling]="item.link === activeLink ? 'preserve' : null"
            [matTooltip]="item.title | translate"
            [matTooltipDisabled]="!condensed"
            matTooltipPosition="right"
          >
            <img class="start-icon" *ngIf="item?.icon" [src]="item?.icon" />
            <span>{{ item.title | translate }}</span>
          </button>
        </ng-container>

        <!-- Menu is condensed and item has a submenu -->
        <ng-container *ngIf="item.submenu && condensed">
          <button
            class="menu-button"
            mat-button
            [matTooltip]="item.title | translate"
            [matTooltipDisabled]="!condensed"
            matTooltipPosition="right"
            [matMenuTriggerFor]="itemSubmenu"
          >
            <img class="start-icon" *ngIf="item?.icon" [src]="item?.icon" />
            <span>{{ item.title | translate }}</span>
          </button>

          <mat-menu #itemSubmenu="matMenu">
            <ng-container *ngIf="item.submenu">
              <button
                mat-menu-item
                *ngFor="let subitem of item.submenu"
                class="menu-button"
                [routerLink]="item?.link || []"
                [routerLinkActive]="item.link ? 'active' : ''"
              >
                {{ subitem.title | translate }}
              </button>
            </ng-container>
          </mat-menu>
        </ng-container>

        <div
          *ngIf="item.submenu && !condensed"
          class="expansion-panel"
          [ngStyle]="{ maxHeight: item?.submenu?.length * 42 + (item?.submenu?.length - 1) * 2 + 4 + 'px' }"
        >
          <button
            *ngFor="let subitem of item.submenu"
            mat-button
            class="menu-button"
            [routerLink]="subitem?.link || []"
            [routerLinkActive]="subitem.link ? 'active' : ''"
          >
            {{ subitem.title | translate }}
          </button>
        </div>
      </div>
    </section>

    <!-- Just functional logout button-->
    <div class="collapse-button">
      <button
        class="menu-button logout-button loading-dark"
        mat-button
        (click)="onLogout()"
        [appLoading]="isLoggingOut"
        [matTooltip]="'navbar.logout' | translate"
        [matTooltipDisabled]="!condensed"
        matTooltipPosition="right"
      >
        <i class="icon start-icon">logout</i>
        <span>{{ 'navbar.logout' | translate }}</span>
      </button>
      <button
        *ngIf="!isMobile"
        class="menu-button loading-dark"
        mat-button
        [matTooltip]="'navbar.collapseMenu' | translate"
        [matTooltipDisabled]="!condensed"
        matTooltipPosition="right"
        (click)="menuToggle.emit()"
      >
        <img *ngIf="!condensed" class="start-icon" src="/assets/icons/collapse.svg" />
        <img *ngIf="condensed" class="start-icon" src="/assets/icons/expand.svg" />
        <span>{{ 'navbar.collapseMenu' | translate }}</span>
      </button>
    </div>
  </nav>
</div>
