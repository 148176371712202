import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-create-new-password',
  templateUrl: './create-new-password.component.html',
  styleUrls: ['./create-new-password.component.scss'],
})
export class CreateNewPasswordComponent implements OnInit, OnDestroy {
  hide = true;
  password: string;

  isLoading: boolean;
  error: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService
  ) {}

  ngOnInit(): void {}

  async resetPassword() {
    if (this.isLoading) return;
    try {
      this.error = null;
      this.isLoading = true;
      const token = this.route.snapshot.paramMap.get('token');
      await this.authService.resetPassword(token, this.password);
      const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/login';
      this.router.navigate([returnUrl]);
      this.toast.showSuccess('toast.success.passwordChanged');
    } catch (e) {
      this.toast.showError(e, false, '', true);
    } finally {
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.toast.onDismiss();
  }
}
