import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../utils/string.utils';

@Pipe({
  name: 'textHumanize',
})
export class TextHumanizePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return StringUtils.textHumanize(value);
  }
}

@Pipe({
  name: 'textHumanizeCapitalizeWords',
})
export class TextHumanizeCapitalizeWordsPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return StringUtils.textHumanizeCapitalizeWords(value);
  }
}
