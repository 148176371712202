<section>
  <app-logo [withTitle]="true" [graysLevel]="'600'"></app-logo>
  <mat-card>
    <mat-card-title>{{ 'createNewPassword.title' | translate }}</mat-card-title>
    <mat-card-content>
      <form #createNewPassword="ngForm">
        <div class="form-group">
          <label for="password">{{ 'createNewPassword.passwordLabel' | translate }}</label>
          <input
            [(ngModel)]="password"
            [type]="hide ? 'password' : 'text'"
            id="password"
            name="password"
            (keyup.enter)="resetPassword()"
            [placeholder]="'createNewPassword.passwordInput' | translate"
            minlength="6"
            required
            #passwordModel="ngModel"
          />
          <app-input-error>{{ 'createNewPassword.passwordError' | translate }}</app-input-error>
          <button mat-icon-button class="suffix transparent-bg customShowPass" (click)="hide = !hide">
            <mat-icon [inline]="true" [svgIcon]="hide ? 'show_off' : 'show_on'"></mat-icon>
          </button>
        </div>
        <div class="details">
          {{ 'createNewPassword.details' | translate }}
        </div>
        <div class="form-group">
          <button
            mat-flat-button
            color="primary"
            (click)="resetPassword()"
            [disabled]="passwordModel.invalid"
            [appLoading]="isLoading"
          >
            {{ 'createNewPassword.buttonReset' | translate }}
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</section>
