import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Directive({
  selector: '[limitMaxLength]',
})
export class MaxLengthDirective {
  @Input('limitMaxLength') limitMaxLength: number;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public platform: Platform) {}

  @HostListener('keyup', ['$event']) onKeyUp(event) {
    const element = event.target as HTMLInputElement;
    element.value = element.value.substr(0, this.limitMaxLength);
    this.ngModelChange.emit(element.value);
  }
}
