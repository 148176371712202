<section>
  <app-logo [withTitle]="true" [graysLevel]="'600'"></app-logo>
  <mat-card>
    <mat-card-title>{{ 'connectDiscogs.title' | translate }}</mat-card-title>
    <mat-card-content>
      <form>
        <div class="form-group">
          <button mat-flat-button color="primary" [disabled]="isLoading" (click)="connect()">
            {{ 'connectDiscogs.buttonConnect' | translate }}
          </button>
        </div>
      </form>
    </mat-card-content>
    <mat-card-footer>
      <div>
        {{ 'connectDiscogs.footerText1' | translate }}
        <a routerLink="/">{{ 'connectDiscogs.footerText2' | translate }}</a>
      </div>
    </mat-card-footer>
  </mat-card>
</section>
